import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IDVIReport, Trailer, Vehicle } from '../../interfaces/PreventableMaintenance/dvir.report';

export const dvirReportApi = createApi({
    reducerPath: 'pm_dvir_report',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/auth/api/dvir-report`
    }),
    tagTypes: ['DVIRReport'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createDvirReport: builder.mutation<
            {
                report: IDVIReport;
                files: { id: number; url: string; name: string }[];
            },
            Partial<IDVIReport>
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body: {
                    ...body,
                    company: body?.company?.id,
                    member: body?.member?.id,
                    vehicle: body?.vehicle?.id,
                    trailer: body?.trailer?.id || undefined,
                    maintenance_member: body?.maintenance_member?.id,
                    date: Intl.DateTimeFormat('en-CA', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    }).format(new Date(body?.date!)),
                    files: body?.image_list
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        updateDvirReport: builder.mutation<IDVIReport, Partial<IDVIReport>>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body: {
                    ...body,
                    company: body?.company?.id,
                    member: body?.member?.id,
                    vehicle: body?.vehicle?.id,
                    trailer: body?.trailer?.id || undefined,
                    maintenance_member: body?.maintenance_member?.id,
                    date: Intl.DateTimeFormat('en-CA', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    }).format(new Date(body?.date!))
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        deleteDvirReport: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        getDvirReport: builder.query<IDVIReport, number | void>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        getDvirReports: builder.query<IDVIReport[], void>({
            query: () => ({
                url: '/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        getDriverReports: builder.query<IDVIReport[], number | void>({
            query: (driver_id: number) => ({
                url: `/driver-reports?member_id=${driver_id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        getCompanyDvir: builder.query<IDVIReport, number | void>({
            query: (id) => ({
                url: `/get-company-dvi-report?company_id=${id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        }),
        getMaintenanceMemberDvir: builder.query<IDVIReport[], number | void>({
            query: (id) => ({
                url: `/get-maintenance-member-dvir?maintenance_member_id=${id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        }),
        createVehicle: builder.mutation<Vehicle, Partial<Vehicle>>({
            query: (body) => ({
                url: '/create-vehicle/',
                method: 'POST',
                body: {
                    ...body,
                    dvir_report: body?.report?.id,
                    lights: JSON.stringify(body?.lights)
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        updateVehicle: builder.mutation<Vehicle, Partial<Vehicle>>({
            query: (body) => ({
                url: `/update-vehicle/`,
                method: 'PUT',
                body: {
                    ...body,
                    dvir_report: body?.report?.id,
                    lights: JSON.stringify(body?.lights)
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        deleteVehicle: builder.mutation<void, number>({
            query: (id) => ({
                url: `/delete-vehicle/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        getVehicle: builder.query<Vehicle, number | void>({
            query: (id) => ({
                url: `/get-vehicle/${id}/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        getVehicles: builder.query<Vehicle[], void>({
            query: () => ({
                url: '/get-vehicles/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        createTrailer: builder.mutation<Trailer, Partial<Trailer>>({
            query: (body) => ({
                url: '/create-trailer/',
                method: 'POST',
                body: {
                    ...body,
                    dvir_report: body?.report?.id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        updateTrailer: builder.mutation<Trailer, Partial<Trailer>>({
            query: (body) => ({
                url: `/update-trailer/`,
                method: 'PUT',
                body: {
                    ...body,
                    dvir_report: body?.report?.id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        deleteTrailer: builder.mutation<void, number>({
            query: (id) => ({
                url: `/delete-trailer/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        getTrailer: builder.query<Trailer, number | void>({
            query: (id) => ({
                url: `/get-trailer/${id}/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        getTrailers: builder.query<Trailer[], void>({
            query: () => ({
                url: '/get-trailers/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DVIRReport', id: 'DVIRReport' }]
        }),
        // get vehicle by report id
        getVehicleByReport: builder.query<Vehicle, number | void>({
            query: (id) => ({
                url: `/get-vehicle-by-report?report_id=${id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        }),
        // get trailer by report id
        getTrailerByReport: builder.query<Trailer, number | void>({
            query: (id) => ({
                url: `/get-trailer-by-report?report_id=${id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        })
    })
});

export const {
    useCreateDvirReportMutation,
    useUpdateDvirReportMutation,
    useDeleteDvirReportMutation,
    useGetDvirReportQuery,
    useGetDvirReportsQuery,
    useCreateVehicleMutation,
    useUpdateVehicleMutation,
    useDeleteVehicleMutation,
    useGetVehicleQuery,
    useGetVehiclesQuery,
    useCreateTrailerMutation,
    useUpdateTrailerMutation,
    useDeleteTrailerMutation,
    useGetTrailerQuery,
    useGetTrailersQuery,
    useGetCompanyDvirQuery,
    useGetDriverReportsQuery,
    useGetVehicleByReportQuery,
    useGetTrailerByReportQuery,
    useGetMaintenanceMemberDvirQuery
} = dvirReportApi;
