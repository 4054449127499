import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IProvidedEducationMaterialsDto,
    IProvidedEducationMaterials
} from '../../interfaces/DrugandAlcoholEmployeesCertifiedReceipt/provided-education-materials';

export const providedEducationMaterialsApi = createApi({
    reducerPath: 'provided_education_materials',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/provided-education-materials`
    }),
    keepUnusedDataFor: 60 * 10, // 10 minutes
    tagTypes: ['ProvidedEducationMaterials'],
    endpoints: (builder) => ({
        createProvidedEducationMaterials: builder.mutation<IProvidedEducationMaterials, IProvidedEducationMaterialsDto>(
            {
                query: (body) => ({
                    url: '/',
                    method: 'POST',
                    body,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                invalidatesTags: [
                    {
                        type: 'ProvidedEducationMaterials',
                        id: 'ProvidedEducationMaterials'
                    }
                ]
            }
        ),
        updateProvidedEducationMaterials: builder.mutation<IProvidedEducationMaterials, IProvidedEducationMaterials>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'ProvidedEducationMaterials',
                    id: 'ProvidedEducationMaterials'
                }
            ]
        }),
        getProvidedEducationMaterials: builder.query<IProvidedEducationMaterials, number | void>({
            query: (id) => ({
                url: '/get-provided-education-materials/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ProvidedEducationMaterials', id: 'ProvidedEducationMaterials' }]
        }),
        getProvidedEducationMaterialsByUser: builder.query<IProvidedEducationMaterials, void>({
            query: () => ({
                url: '/get-provided-education-materials-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ProvidedEducationMaterials', id: 'ProvidedEducationMaterials' }]
        }),
        getProvidedEducationMaterialsByUserId: builder.query<IProvidedEducationMaterials, number>({
            query: (id) => ({
                url: '/get-provided-education-materials-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ProvidedEducationMaterials', id: 'ProvidedEducationMaterials' }]
        }),
        deleteProvidedEducationMaterials: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'ProvidedEducationMaterials',
                    id: 'ProvidedEducationMaterials'
                }
            ]
        })
    })
});

export const {
    useCreateProvidedEducationMaterialsMutation,
    useUpdateProvidedEducationMaterialsMutation,
    useGetProvidedEducationMaterialsQuery,
    useGetProvidedEducationMaterialsByUserQuery,
    useGetProvidedEducationMaterialsByUserIdQuery,
    useDeleteProvidedEducationMaterialsMutation
} = providedEducationMaterialsApi;
