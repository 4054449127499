import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IPreviousPreEmploymentDrugWitnessSignature,
    IPreviousPreEmploymentDrugWitnessSignatureDto
} from '../../interfaces/PreviousPreEmploymentDrugAndAlcoholTestStatement/PreviousPreEmploymentDrugWitnessSignature';

export const previousPreEmploymentDrugWitnessSignatureApi = createApi({
    reducerPath: 'previous_pre_employment_drug_witness_signature',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/previous-drug-witness-signature`
    }),
    tagTypes: ['PreviousPreEmploymentDrugWitnessSignature'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createPreviousPreEmploymentDrugWitnessSignature: builder.mutation<
            IPreviousPreEmploymentDrugWitnessSignature,
            IPreviousPreEmploymentDrugWitnessSignatureDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PreviousPreEmploymentDrugWitnessSignature',
                    id: 'PreviousPreEmploymentDrugWitnessSignature'
                }
            ]
        }),
        updatePreviousPreEmploymentDrugWitnessSignature: builder.mutation<
            IPreviousPreEmploymentDrugWitnessSignature,
            IPreviousPreEmploymentDrugWitnessSignature
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PreviousPreEmploymentDrugWitnessSignature',
                    id: 'PreviousPreEmploymentDrugWitnessSignature'
                }
            ]
        }),
        deletePreviousPreEmploymentDrugWitnessSignature: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'PreviousPreEmploymentDrugWitnessSignature',
                    id: 'PreviousPreEmploymentDrugWitnessSignature'
                }
            ]
        }),
        getPreviousPreEmploymentDrugWitnessSignature: builder.query<
            IPreviousPreEmploymentDrugWitnessSignature,
            number | void
        >({
            query: (id) => ({
                url: `/get-previous-drug-witness-signature/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PreviousPreEmploymentDrugWitnessSignature',
                    id: 'PreviousPreEmploymentDrugWitnessSignature'
                }
            ]
        }),
        getPreviousPreEmploymentDrugWitnessSignatureByUser: builder.query<
            IPreviousPreEmploymentDrugWitnessSignature,
            void
        >({
            query: () => ({
                url: `/get-previous-drug-witness-signature-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PreviousPreEmploymentDrugWitnessSignature',
                    id: 'PreviousPreEmploymentDrugWitnessSignature'
                }
            ]
        }),
        getPreviousPreEmploymentDrugWitnessSignatureByUserId: builder.query<
            IPreviousPreEmploymentDrugWitnessSignature,
            number | void
        >({
            query: (id) => ({
                url: `/get-previous-drug-witness-signature-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'PreviousPreEmploymentDrugWitnessSignature',
                    id: 'PreviousPreEmploymentDrugWitnessSignature'
                }
            ]
        })
    })
});

export const {
    useCreatePreviousPreEmploymentDrugWitnessSignatureMutation,
    useUpdatePreviousPreEmploymentDrugWitnessSignatureMutation,
    useGetPreviousPreEmploymentDrugWitnessSignatureQuery,
    useGetPreviousPreEmploymentDrugWitnessSignatureByUserQuery,
    useGetPreviousPreEmploymentDrugWitnessSignatureByUserIdQuery,
    useDeletePreviousPreEmploymentDrugWitnessSignatureMutation
} = previousPreEmploymentDrugWitnessSignatureApi;
