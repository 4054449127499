import { Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Slide, TextField } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { FunctionComponent, ReactElement, Ref, forwardRef, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../../modules/auth';
import { IPmProgram } from '../../../../store/interfaces/PreventableMaintenance/pm.program';
import {
    useGetMaintenanceVehiclesByCompanyQuery,
    useUpdateMaintenanceVehicleMutation
} from '../../../../store/reducers/PreventableMaintenance/maintenance.vehicle';
import { useCompany } from '../../../../context/CompanyContext';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { toast } from 'react-toastify';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    pmProgram?: IPmProgram;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues: Partial<IMaintenanceVehicle> = {
    pm_program: undefined,
    id: 0
};

const validationSchema = Yup.object().shape({
    pm_program: Yup.object().required('PM Program is required')
});

export const VehicleListModal: FunctionComponent<IProps> = ({ open, setOpen, pmProgram }) => {
    const { currentUser } = useAuth();
    const { currentCompany } = useCompany();
    const [updateMaintenanceVehicle] = useUpdateMaintenanceVehicleMutation();
    const { data = [] } = useGetMaintenanceVehiclesByCompanyQuery(
        Number(currentUser?.company?.id) ?? Number(currentCompany?.id),
        {
            refetchOnFocus: true,
            skip: !currentUser?.company?.id && !currentCompany?.id
        }
    );

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            updateMaintenanceVehicle({
                ...values,
                pm_program: pmProgram
            }).then(() => {
                toast.success('PM Program assigned to vehicle successfully');
                setOpen(false);
                formik.resetForm();
            });
        }
    });

    useEffect(() => {
        if (open && pmProgram) {
            formik.setFieldValue('pm_program', pmProgram);
        }
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding: '20px 20px 20px 20px'
                }}
            >
                Assign PM Program to Vehicle
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} p={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="vehicle"
                                name="vehicle"
                                label="Vehicle Number"
                                size="small"
                                value={formik.values.id}
                                onChange={(e) => {
                                    const vehicle = data.find((vehicle) => vehicle.id === Number(e.target.value));
                                    if (vehicle) {
                                        formik.setValues({
                                            ...vehicle,
                                            pm_program: pmProgram
                                        });
                                    }
                                }}
                                error={formik.touched.id && Boolean(formik.errors.id)}
                                helperText={formik.touched.id && formik.errors.id}
                                select
                            >
                                {data.map((vehicle) => (
                                    <MenuItem key={vehicle.id} value={vehicle.id}>
                                        {vehicle.vehicle_number}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" size="small" fullWidth>
                                Assign PM Program
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};
