import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    ExperienceAndQualifications,
    ExperienceAndQualificationsDto,
    LicenseQuestions,
    LicenseQuestionsDto
} from '../../interfaces/EmploymentHistory/experience-and-qualifications';

export const experienceAndQualificationsApi = createApi({
    reducerPath: 'experience_and_qualifications',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/experience-and-qualifications`
    }),
    tagTypes: ['ExperienceAndQualifications'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createExperienceAndQualifications: builder.mutation<
            ExperienceAndQualifications,
            ExperienceAndQualificationsDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        }),
        updateExperienceAndQualifications: builder.mutation<ExperienceAndQualifications, ExperienceAndQualifications>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        }),
        getExperienceAndQualifications: builder.query<ExperienceAndQualifications[], number | void>({
            query: (id) => ({
                url: '/get-experience-and-qualifications/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        }),
        getExperienceAndQualificationsByUser: builder.query<ExperienceAndQualifications[], void>({
            query: () => ({
                url: '/get-experience-and-qualifications-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        }),
        getExperieneAndQualificationsByUserId: builder.query<ExperienceAndQualifications[], number | void>({
            query: (id) => ({
                url: '/get-experience-and-qualifications-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        }),
        deleteExperienceAndQualificationsMutation: builder.mutation<void, number | void>({
            query: (id) => ({
                url: `/delete-experience-and-qualifications/`,
                method: 'POST',
                body: {
                    id: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        }),
        createLicenseQuestions: builder.mutation<LicenseQuestions, LicenseQuestionsDto>({
            query: (body) => ({
                url: '/create-license-questions/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        }),
        updateLicenseQuestions: builder.mutation<LicenseQuestions, LicenseQuestions>({
            query: (body) => ({
                url: `/update-license-questions/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        }),
        getLicenseQuestions: builder.query<LicenseQuestions, number | void>({
            query: (id) => ({
                url: '/get-license-questions/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        }),
        getLicenseQuestionsByUser: builder.query<LicenseQuestions, void>({
            query: () => ({
                url: '/get-license-questions-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        }),
        getLicenseQuestionsByUserId: builder.query<LicenseQuestions, number | void>({
            query: (id) => ({
                url: '/get-license-questions-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ExperienceAndQualifications', id: 'ExperienceAndQualifications' }]
        })
    })
});

export const {
    useCreateExperienceAndQualificationsMutation,
    useUpdateExperienceAndQualificationsMutation,
    useGetExperienceAndQualificationsQuery,
    useGetExperienceAndQualificationsByUserQuery,
    useGetExperieneAndQualificationsByUserIdQuery,
    useCreateLicenseQuestionsMutation,
    useUpdateLicenseQuestionsMutation,
    useGetLicenseQuestionsQuery,
    useGetLicenseQuestionsByUserQuery,
    useGetLicenseQuestionsByUserIdQuery,
    useDeleteExperienceAndQualificationsMutationMutation
} = experienceAndQualificationsApi;
