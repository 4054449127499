import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    ITerminationOfEmployment,
    ITerminationOfEmploymentDto
} from '../../interfaces/DriversApplicationForEmployment/termination.of.employment';

export const terminationOfEmploymentApi = createApi({
    reducerPath: 'termination_of_employment',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/termination-of-employment`
    }),
    tagTypes: ['TerminationOfEmployment'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createTerminationOfEmployment: builder.mutation<ITerminationOfEmployment, ITerminationOfEmploymentDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'TerminationOfEmployment', id: 'TerminationOfEmployment' }]
        }),
        updateTerminationOfEmployment: builder.mutation<ITerminationOfEmployment, ITerminationOfEmployment>({
            query: (body) => ({
                url: `/${body.id}`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'TerminationOfEmployment', id: 'TerminationOfEmployment' }]
        }),
        deleteTerminationOfEmployment: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'TerminationOfEmployment', id: 'TerminationOfEmployment' }]
        }),
        getTerminationOfEmployment: builder.mutation<ITerminationOfEmployment, number | void>({
            query: (id) => ({
                url: '/termination-of-employment/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        }),
        getTerminationOfEmploymentByUser: builder.query<ITerminationOfEmployment, void>({
            query: () => ({
                url: '/termination-of-employment-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'TerminationOfEmployment', id: 'TerminationOfEmployment' }]
        }),
        getTerminationOfEmploymentByUserId: builder.query<ITerminationOfEmployment, number | void>({
            query: (id) => ({
                url: '/termination-of-employment-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'TerminationOfEmployment', id: 'TerminationOfEmployment' }]
        })
    })
});

export const {
    useCreateTerminationOfEmploymentMutation,
    useUpdateTerminationOfEmploymentMutation,
    useGetTerminationOfEmploymentMutation,
    useGetTerminationOfEmploymentByUserQuery,
    useGetTerminationOfEmploymentByUserIdQuery,
    useDeleteTerminationOfEmploymentMutation
} = terminationOfEmploymentApi;
