import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { ITrafficConvictions, ITrafficConvictionsDto } from '../../interfaces/EmploymentHistory/traffic-convictions';

export interface IHasTrafficConvictions {
    member: number;
    company: number;
    has_traffic_convictions: boolean;
    id: number;
}

export type IHasTrafficConvictionsDto = {
    memberId: number;
    companyId: number;
    has_traffic_convictions: boolean;
};

export const trafficConvictionsApi = createApi({
    reducerPath: 'traffic_convictions',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/traffic-convictions`
    }),
    tagTypes: ['TrafficConvictions'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createTrafficConvictions: builder.mutation<ITrafficConvictions, ITrafficConvictionsDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'TrafficConvictions', id: 'TrafficConvictions' }]
        }),
        updateTrafficConvictions: builder.mutation<ITrafficConvictions, ITrafficConvictions>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'TrafficConvictions', id: 'TrafficConvictions' }]
        }),
        getTrafficConvictions: builder.query<ITrafficConvictions[], number | void>({
            query: (id) => ({
                url: '/get-traffic-convictions/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'TrafficConvictions', id: 'TrafficConvictions' }]
        }),
        getTrafficConvictionsByUser: builder.query<ITrafficConvictions[], void>({
            query: () => ({
                url: '/get-traffic-convictions-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'TrafficConvictions', id: 'TrafficConvictions' }]
        }),
        getTrafficConvictionsByUserId: builder.query<ITrafficConvictions[], number | void>({
            query: (id) => ({
                url: '/get-traffic-convictions-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'TrafficConvictions', id: 'TrafficConvictions' }]
        }),
        deleteTrafficConvictions: builder.mutation<void, number>({
            query: (id) => ({
                url: `/delete-traffic-convictions/`,
                method: 'POST',
                body: {
                    id: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'TrafficConvictions', id: 'TrafficConvictions' }]
        }),
        getHasTrafficConvictions: builder.query<IHasTrafficConvictions, number | void>({
            query: (id) => ({
                url: '/get-has-traffic-convictions/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'TrafficConvictions', id: 'TrafficConvictions' }]
        }),
        hasTrafficConvictions: builder.mutation<IHasTrafficConvictions, Partial<IHasTrafficConvictions>>({
            query: (body) => ({
                url: '/has-traffic-convictions/',
                method: 'POST',
                body: {
                    member: body.member,
                    company: body.company,
                    has_traffic_convictions: body.has_traffic_convictions,
                    id: body?.id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'TrafficConvictions', id: 'TrafficConvictions' }]
        })
    })
});

export const {
    useCreateTrafficConvictionsMutation,
    useUpdateTrafficConvictionsMutation,
    useGetTrafficConvictionsQuery,
    useGetTrafficConvictionsByUserQuery,
    useGetTrafficConvictionsByUserIdQuery,
    useDeleteTrafficConvictionsMutation,
    useGetHasTrafficConvictionsQuery,
    useHasTrafficConvictionsMutation
} = trafficConvictionsApi;
