import React, { useEffect, useState } from 'react';
import { IMember, IPreEmploymentInvestigation, InvestigationType } from '../../../../models';
import TableWrapper from '../../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainer from '../../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableContainerHeaderTitle from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableContainerHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import PreEmploymentRow from './PreEmploymentRow';
import { getPreEmploymentProducts, getSubscriptionPlans } from '../../../../shared';
import { StripeProduct } from '../../models';

interface IProps {
    investigations: IPreEmploymentInvestigation[];
    member: IMember;
    subscriptions: StripeProduct[];
    memberSubscription: StripeProduct | undefined;
}

const PreEmploymentInvestigationTable = ({ investigations, member, subscriptions, memberSubscription }: IProps) => {
    const [options, setOptions] = useState<Array<StripeProduct>>([]);
    const [investigationTypes, setInvestigationTypes] = useState<InvestigationType[]>([]);
    const getPlanOptions = React.useCallback(async () => {
        if (memberSubscription) {
            const res = await getPreEmploymentProducts(memberSubscription.name);
            const products: { data: StripeProduct[] } = await getSubscriptionPlans();
            const backgroundCheck = products.data.find(
                (product) => product.name.toLowerCase() === 'Criminal Background Check'.toLowerCase()
            );
            if (
                memberSubscription.name.toLowerCase() === 'Master Package'.toLowerCase() ||
                memberSubscription.name.toLowerCase() === 'Deluxe Package'.toLowerCase() ||
                'Deluxe Mini Package'.toLowerCase()
            ) {
                setOptions(res.data);
                setInvestigationTypes(Object.values(InvestigationType));
            }
            if (memberSubscription.name.toLowerCase() === 'Driver File Package'.toLowerCase()) {
                setOptions(
                    res.data.filter(
                        (option: StripeProduct) =>
                            option.name !== 'Pre-Employment DACH Query' &&
                            option.name !== 'Pre-Employment Drug Test with negative result'
                    )
                );
                setInvestigationTypes(
                    Object.values(InvestigationType).filter(
                        (item) =>
                            item !== InvestigationType.PRE_EMPLOYMENT_FULL_DACH_QUERY &&
                            item !== InvestigationType.PRE_EMPLOYMENT_DRUG_TEST
                    )
                );
            }
            if (memberSubscription.name.toLowerCase() === 'Drug and Alcohol Package'.toLowerCase()) {
                setOptions(
                    res.data.filter(
                        (option: StripeProduct) =>
                            option.name === 'Pre-Employment DACH Query' ||
                            option.name === 'Pre-Employment Drug Test with negative result'
                    )
                );
                setInvestigationTypes([
                    InvestigationType.PRE_EMPLOYMENT_FULL_DACH_QUERY,
                    InvestigationType.PRE_EMPLOYMENT_DRUG_TEST
                ]);
            }
        }
    }, [memberSubscription]);

    useEffect(() => {
        getPlanOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPlanOptions]);

    return (
        <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <div className="card-body d-flex flex-column p-9">
                        <TableWrapper>
                            <TableContainerHeader>
                                <TableContainerHeaderTitle
                                    title="Pre-Employment Investigations"
                                    subtitle={
                                        memberSubscription?.name !== 'Deluxe Mini Package'
                                            ? 'Please note that fees will be reimbursed for all pre-employment investigations on the drivers 3 month anniversary of employment with the company after they are hired'
                                            : ''
                                    }
                                />
                            </TableContainerHeader>
                            <TableContainer>
                                <TableHeader>
                                    <TableHeaderColumn>Type</TableHeaderColumn>
                                    <TableHeaderColumn>Progress</TableHeaderColumn>
                                    <TableHeaderColumn>Requires Payment</TableHeaderColumn>
                                    <TableHeaderColumn>Started</TableHeaderColumn>
                                    <TableHeaderColumn>Date Started</TableHeaderColumn>
                                    <TableHeaderColumn>Payment Status</TableHeaderColumn>
                                    <TableHeaderColumn>Price</TableHeaderColumn>
                                </TableHeader>
                                <tbody>
                                    {investigations
                                        .sort((a, b) => (a?.date_started! > b?.date_started! ? 1 : -1))
                                        .map((investigation, index) => {
                                            const option = options.find((option) =>
                                                option.name.toLowerCase().includes(investigation.type.toLowerCase())
                                            );

                                            return (
                                                <PreEmploymentRow
                                                    key={index}
                                                    investigation={investigation}
                                                    price={option ? option.unit_amount / 100 : undefined}
                                                />
                                            );
                                        })}
                                    {investigationTypes
                                        .filter(
                                            (item) =>
                                                item !==
                                                investigations.find((investigation) => investigation.type === item)
                                                    ?.type
                                        )
                                        .map((item, index) => {
                                            const option = options.find((option) =>
                                                item.toLowerCase().includes(option.name.toLowerCase())
                                            );
                                            return (
                                                <PreEmploymentRow
                                                    key={index}
                                                    price={option ? option.unit_amount / 100 : undefined}
                                                    investigation={{
                                                        type: item,
                                                        is_current_investigation: false,
                                                        requires_payment: false,
                                                        date_started: undefined,
                                                        payment: '',
                                                        screened: false
                                                    }}
                                                />
                                            );
                                        })}
                                </tbody>
                            </TableContainer>
                        </TableWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreEmploymentInvestigationTable;
