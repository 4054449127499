import React from 'react';
import { IDVIReport } from '../../../../store/interfaces/PreventableMaintenance/dvir.report';
import { Box, Grid, Paper, Table, TableBody, TableCell, TablePagination, TableRow, Typography } from '@mui/material';
import TableHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import SignatureField from './SignatureField';
import DateComponent from '../DateComponent';

interface IProps {
    dvirList: IDVIReport[];
}

const DriverVehicleInspectionReport: React.FunctionComponent<IProps> = ({ dvirList }) => {
    const [page, setPage] = React.useState<number>(0);

    const [limit, setLimit] = React.useState<number>(10);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    return (
        <Box component={Paper} elevation={2} boxShadow={3} sx={{ borderRadius: 2 }} p={2} mt={3}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                        Driver-Vehicle Inspection Reports
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'green' }}>
                        Past 90 Days of Driver Vehicle Inspection Reports for this vehicle
                    </Typography>
                </Grid>
            </Grid>
            <Table stickyHeader sx={{ maxHeight: 440 }}>
                <TableHeader>
                    <TableHeaderColumn>File Name</TableHeaderColumn>
                    <TableHeaderColumn>Driver Name</TableHeaderColumn>
                    <TableHeaderColumn>Start Odometer Reading</TableHeaderColumn>
                    <TableHeaderColumn>End Odometer Reading</TableHeaderColumn>
                    <TableHeaderColumn>Mechanic / Next Driver Signature</TableHeaderColumn>
                    <TableHeaderColumn>Date of DVIR</TableHeaderColumn>
                </TableHeader>
                <TableBody>
                    {dvirList?.slice(page * limit, page * limit + limit)?.map((dvir, index) => (
                        <TableRow
                            key={index}
                            hover
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                window.location.assign(`/driver-inspection-report/${dvir?.id}`);
                            }}
                        >
                            <TableCell>DVIR {index + 1}</TableCell>
                            <TableCell>
                                {dvir?.member?.user?.first_name ?? ''} {dvir?.member?.user?.last_name ?? ''}
                            </TableCell>
                            <TableCell>
                                {Intl.NumberFormat('en-US', {
                                    compactDisplay: 'short'
                                }).format(Number(dvir.start_odometer_reading))}{' '}
                                Miles
                            </TableCell>
                            <TableCell>
                                {Intl.NumberFormat('en-US', {
                                    compactDisplay: 'short'
                                }).format(Number(dvir.end_odometer_reading))}{' '}
                                Miles
                            </TableCell>
                            <SignatureField dvir={dvir} />
                            <DateComponent date={dvir.date ?? new Date()} />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {/* </TableWrapper> */}
            <TablePagination
                component="div"
                count={dvirList.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[1, 10, 25, 30]}
            />
        </Box>
    );
};

export default DriverVehicleInspectionReport;
