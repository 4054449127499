import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IWitnessDriverCertificationForOtherCompensatedWork,
    IWitnessDriverCertificationForOtherCompensatedWorkDto
} from '../../interfaces/DriversStatementDutyHours/witness-driver-certification-for-other-compensated-work';

export const witnessDriverCertificationForOtherCompensatedWorkApi = createApi({
    reducerPath: 'witness_driver_certification_for_other_compensated_work',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/witness-driver-certification-for-other-compensated-work`
    }),
    tagTypes: ['WitnessDriverCertificationForOtherCompensatedWork'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createWitnessDriverCertificationForOtherCompensatedWork: builder.mutation<
            IWitnessDriverCertificationForOtherCompensatedWork,
            IWitnessDriverCertificationForOtherCompensatedWorkDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'WitnessDriverCertificationForOtherCompensatedWork',
                    id: 'WitnessDriverCertificationForOtherCompensatedWork'
                }
            ]
        }),
        updateWitnessDriverCertificationForOtherCompensatedWork: builder.mutation<
            IWitnessDriverCertificationForOtherCompensatedWork,
            IWitnessDriverCertificationForOtherCompensatedWork
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'WitnessDriverCertificationForOtherCompensatedWork',
                    id: 'WitnessDriverCertificationForOtherCompensatedWork'
                }
            ]
        }),
        deleteWitnessDriverCertificationForOtherCompensatedWork: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'WitnessDriverCertificationForOtherCompensatedWork',
                    id: 'WitnessDriverCertificationForOtherCompensatedWork'
                }
            ]
        }),
        getWitnessDriverCertificationForOtherCompensatedWork: builder.query<
            IWitnessDriverCertificationForOtherCompensatedWork,
            number | void
        >({
            query: (id) => ({
                url: `/get-witness-driver-certification-for-other-compensated-work/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'WitnessDriverCertificationForOtherCompensatedWork',
                    id: 'WitnessDriverCertificationForOtherCompensatedWork'
                }
            ]
        }),
        getWitnessDriverCertificationForOtherCompensatedWorkByUser: builder.query<
            IWitnessDriverCertificationForOtherCompensatedWork,
            void
        >({
            query: () => ({
                url: `/get-witness-driver-certification-for-other-compensated-work-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'WitnessDriverCertificationForOtherCompensatedWork',
                    id: 'WitnessDriverCertificationForOtherCompensatedWork'
                }
            ]
        }),
        getWitnessDriverCertificationForOtherCompensatedWorkByUserId: builder.query<
            IWitnessDriverCertificationForOtherCompensatedWork,
            number
        >({
            query: (id) => ({
                url: `/get-witness-driver-certification-for-other-compensated-work-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'WitnessDriverCertificationForOtherCompensatedWork',
                    id: 'WitnessDriverCertificationForOtherCompensatedWork'
                }
            ]
        })
    })
});

export const {
    useCreateWitnessDriverCertificationForOtherCompensatedWorkMutation,
    useUpdateWitnessDriverCertificationForOtherCompensatedWorkMutation,
    useGetWitnessDriverCertificationForOtherCompensatedWorkQuery,
    useGetWitnessDriverCertificationForOtherCompensatedWorkByUserQuery,
    useGetWitnessDriverCertificationForOtherCompensatedWorkByUserIdQuery,
    useDeleteWitnessDriverCertificationForOtherCompensatedWorkMutation
} = witnessDriverCertificationForOtherCompensatedWorkApi;
