import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IReleaseFormBackgroundCheck,
    IReleaseFormBackgroundCheckDto
} from '../../interfaces/ReleaseFormBackgroundCheck/ReleaseFormBackgroundChecks';
import { IProgress } from '../../interfaces/abstract';

export const releaseFormBackgroundCheckApi = createApi({
    reducerPath: 'release_form_background_check',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/release-forms-background-check/`
    }),
    tagTypes: ['ReleaseFormBackgroundCheck'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createReleaseFormBackgroundCheck: builder.mutation<IReleaseFormBackgroundCheck, IReleaseFormBackgroundCheckDto>(
            {
                query: (body) => ({
                    url: '/',
                    method: 'POST',
                    body,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                invalidatesTags: [{ type: 'ReleaseFormBackgroundCheck', id: 'ReleaseFormBackgroundCheck' }]
            }
        ),
        updateReleaseFormBackgroundCheck: builder.mutation<IReleaseFormBackgroundCheck, IReleaseFormBackgroundCheck>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ReleaseFormBackgroundCheck', id: 'ReleaseFormBackgroundCheck' }]
        }),
        deleteReleaseFormBackgroundCheck: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ReleaseFormBackgroundCheck', id: 'ReleaseFormBackgroundCheck' }]
        }),
        getReleaseFormBackgroundCheck: builder.query<IReleaseFormBackgroundCheck, number>({
            query: (id) => ({
                url: `/get-release-form-background-check/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ReleaseFormBackgroundCheck', id: 'ReleaseFormBackgroundCheck' }]
        }),
        getReleaseFormBackgroundCheckByUser: builder.query<IReleaseFormBackgroundCheck, void>({
            query: () => ({
                url: `/get-release-form-background-check-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ReleaseFormBackgroundCheck', id: 'ReleaseFormBackgroundCheck' }]
        }),
        getReleaseFormBackgroundCheckByUserId: builder.query<IReleaseFormBackgroundCheck, number>({
            query: (id) => ({
                url: `/get-release-form-background-check-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ReleaseFormBackgroundCheck', id: 'ReleaseFormBackgroundCheck' }]
        }),
        getReleaseFormBackgroundCheckProgress: builder.query<IProgress, number | undefined>({
            query: (id) => ({
                url: `/total-progress/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ReleaseFormBackgroundCheck', id: 'ReleaseFormBackgroundCheck' }]
        }),
        getMemberReleaseFormBackgroundCheckProgress: builder.query<IProgress, number | undefined>({
            query: (id) => ({
                url: `/member-progress/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ReleaseFormBackgroundCheck', id: 'ReleaseFormBackgroundCheck' }]
        })
    })
});

export const {
    useCreateReleaseFormBackgroundCheckMutation,
    useUpdateReleaseFormBackgroundCheckMutation,
    useGetReleaseFormBackgroundCheckQuery,
    useGetReleaseFormBackgroundCheckByUserQuery,
    useGetReleaseFormBackgroundCheckByUserIdQuery,
    useGetReleaseFormBackgroundCheckProgressQuery,
    useGetMemberReleaseFormBackgroundCheckProgressQuery,
    useDeleteReleaseFormBackgroundCheckMutation
} = releaseFormBackgroundCheckApi;
