import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    IApplicantSignature,
    IApplicantSignatureDto
} from '../../../store/interfaces/DriversApplicationForEmployment/applicant.signature';
import { IMember } from '../../../models';
import {
    useCreateApplicantSignatureMutation,
    useGetApplicantSignatureByUserIdQuery,
    useDeleteApplicantSignatureMutation
} from '../../../store/reducers/DriversApplicationForEmployment/applicant-signature';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useAuth } from '../../../app/modules/auth';
import { useThemeMode } from '../../partials';
import { useGetApplicantBasicInfoByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/applicant.basic.info';

const initialValues: Partial<IApplicantSignatureDto> = {
    signature: '',
    date_of_hire: ''
};

const validationSchema = Yup.object({
    signature: Yup.string().required('Required').min(2, 'Too Short!'),
    date_of_hire: Yup.string().required('Required')
});

const DriverSignature = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetApplicantSignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id
    });
    const { data: applicantBasicInfo } = useGetApplicantBasicInfoByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });
    const [createApplicantSignature, { isLoading: isCreating }] = useCreateApplicantSignatureMutation();
    const [updateApplicantSignature, { isLoading: isUpdating }] = useCreateApplicantSignatureMutation();
    const [deleteApplicantSignature] = useDeleteApplicantSignatureMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (data) {
                updateApplicantSignature({
                    ...values,
                    // @ts-ignore
                    id: data.id
                });
            } else {
                createApplicantSignature({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
            }
        },
        validateOnBlur: true,
        validate: (values) => {
            const errors: Partial<IApplicantSignature> = {};
            if (!values.signature) {
                errors.signature = 'Required';
            }
            if (!values.date_of_hire) {
                errors.date_of_hire = 'Required';
            }
            return errors;
        }
    });

    React.useEffect(() => {
        if (data) {
            formik.setValues({
                signature: data?.signature,
                date_of_hire: data?.date_of_hire
            });
        } else if (applicantBasicInfo) {
            formik.setFieldValue('date_of_hire', applicantBasicInfo?.date_of_hire);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, applicantBasicInfo]);

    return (
        <div
            style={{
                margin: 10,
                padding: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Applicant Signature
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                TO BE READ AND SIGNED BY APPLICANT
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'justify' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                I understand that information I provide regarding current and/or previous employers may be used, and
                those employer(s) will be contacted, for the purpose of investigating my safety performance history as
                required by 49 CFR 391.23(d) and (e).
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'justify' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                I understand that I have the right to:
            </Typography>
            <div style={{ height: 20 }} />
            <ul>
                <li style={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', fontSize: 14 }}>
                    Review information provided by previous employers
                </li>
                <li style={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', fontSize: 14 }}>
                    Have errors in the information corrected by previous employers and for those previous employers to
                    re-send the corrected information to the prospective employer
                </li>
                <li style={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', fontSize: 14 }}>
                    Have a rebuttal statement attached to the alleged erroneous information, if the previous employer(s)
                    and I cannot agree on the accuracy of the information
                </li>
            </ul>
            <Grid
                container
                spacing={3}
                p={1}
                sx={{
                    alignItems: 'center'
                }}
            >
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Driver's Applicant Signature
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="signature"
                        label="Drivers Applicant Signature"
                        placeholder="Drivers Applicant Signature"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('signature')}
                        className={formik.touched.signature && Boolean(formik.errors.signature) ? 'green' : 'red'}
                        error={formik.touched.signature && Boolean(formik.errors.signature)}
                        helperText={formik.touched.signature && formik.errors.signature}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature && Boolean(formik.errors.signature) ? 'red' : 'green',
                                    fontStyle: 'italic'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature && Boolean(formik.errors.signature) ? 'red' : 'green',
                                    fontStyle: 'italic'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature && Boolean(formik.errors.signature) ? 'red' : 'green',
                                    fontStyle: 'italic'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date of Application
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="date_of_hire"
                        size="small"
                        variant="outlined"
                        type="date"
                        {...formik.getFieldProps('date_of_hire')}
                        className={formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire) ? 'green' : 'red'}
                        error={formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire)}
                        helperText={formik.touched.date_of_hire && formik.errors.date_of_hire}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deleteApplicantSignature(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default DriverSignature;
