import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IPerformanceHistoryFormsComplete,
    IPerformanceHistoryFormsCompleteDto
} from '../../interfaces/PreviousEmployeeSafetyPerformanceHistory/performance-history-forms-complete';

interface ID extends IPerformanceHistoryFormsCompleteDto {
    id: number;
}

export const performanceHistoryFormsCompleteApi = createApi({
    reducerPath: 'performance_history_forms_complete',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/prev-employee-performance-history-forms-complete`
    }),
    tagTypes: ['PerformanceHistoryFormsComplete'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createPerformanceHistoryFormsComplete: builder.mutation<
            IPerformanceHistoryFormsComplete,
            IPerformanceHistoryFormsCompleteDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'PerformanceHistoryFormsComplete', id: 'PerformanceHistoryFormsComplete' }]
        }),
        updatePerformanceHistoryFormsComplete: builder.mutation<IPerformanceHistoryFormsComplete, ID>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        }),
        deletePerformanceHistoryFormsComplete: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'PerformanceHistoryFormsComplete', id: 'PerformanceHistoryFormsComplete' }]
        }),
        getPerformanceHistoryFormsComplete: builder.query<IPerformanceHistoryFormsComplete, number | void>({
            query: (id) => ({
                url: `/get-performance-history-forms-complete/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'PerformanceHistoryFormsComplete', id: 'PerformanceHistoryFormsComplete' }]
        }),
        getPerformanceHistoryFormsCompleteByUser: builder.query<IPerformanceHistoryFormsComplete, void>({
            query: () => ({
                url: '/get-performance-history-forms-complete-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'PerformanceHistoryFormsComplete', id: 'PerformanceHistoryFormsComplete' }]
        }),
        getPerformanceHistoryFormsCompleteByUserId: builder.query<IPerformanceHistoryFormsComplete, number | void>({
            query: (id) => ({
                url: '/get-performance-history-forms-complete-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'PerformanceHistoryFormsComplete', id: 'PerformanceHistoryFormsComplete' }]
        })
    })
});

export const {
    useCreatePerformanceHistoryFormsCompleteMutation,
    useUpdatePerformanceHistoryFormsCompleteMutation,
    useGetPerformanceHistoryFormsCompleteQuery,
    useGetPerformanceHistoryFormsCompleteByUserQuery,
    useGetPerformanceHistoryFormsCompleteByUserIdQuery,
    useDeletePerformanceHistoryFormsCompleteMutation
} = performanceHistoryFormsCompleteApi;
