import {
    Box,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import TableWrapper from '../../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import { KTSVG } from '../../../../compliancemasters/helpers';
import TableContainer from '../../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import { MoreVertRounded } from '@mui/icons-material';
import { INotesAndLinks } from '../../../../store/interfaces/PreventableMaintenance/notes.and.links';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { AddNoteModal } from './AddNoteModal';
import DeleteNoteModal from './DeleteNoteModal';
import { downloadVehicleFile } from '../../../../shared/client/manager-notes';

interface IProps {
    notesAndLinks: INotesAndLinks[];
    vehicle?: IMaintenanceVehicle;
}

const NotesTable: FC<IProps> = ({ notesAndLinks, vehicle }) => {
    const [limit, setLimit] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [addNotesOpen, setAddNotesOpen] = useState(false);
    const [deleteNotesModalOpen, setDeleteNotesModalOpen] = useState(false);
    const [note, setNote] = useState<INotesAndLinks>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    function handleDownloadFile(id: number) {
        downloadVehicleFile(id).then((res) => {
            window.open(res.url, '_blank');
        });
    }

    return (
        <Box component={Paper} elevation={2} boxShadow={3} sx={{ borderRadius: 2 }} p={2} mt={3}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                        Vehicle Notes
                    </Typography>
                </Grid>
            </Grid>
            <TableWrapper>
                <TableContainerHeader>
                    <div
                        className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                        onClick={() => setAddNotesOpen(true)}
                    >
                        <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                        Add Note
                    </div>
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>#</TableHeaderColumn>
                        <TableHeaderColumn>Subject / Title</TableHeaderColumn>
                        <TableHeaderColumn>Desc.</TableHeaderColumn>
                        <TableHeaderColumn>Date / Time</TableHeaderColumn>
                        <TableHeaderColumn>File</TableHeaderColumn>
                        <TableHeaderColumn>{''}</TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {notesAndLinks?.slice(page * limit, page * limit + limit).map((note, index) => (
                            <TableRow hover sx={{ cursor: 'pointer' }} key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{note.note_subject}</TableCell>
                                <TableCell>{note.note}</TableCell>
                                <TableCell>
                                    {note.created_at
                                        ? Intl.DateTimeFormat('en-US', {
                                              year: 'numeric',
                                              month: 'long',
                                              day: '2-digit'
                                          }).format(new Date(note.created_at))
                                        : 'N/A'}
                                    {', '}
                                    {note?.created_at
                                        ? Intl.DateTimeFormat('en-US', {
                                              hour: '2-digit',
                                              minute: '2-digit',
                                              second: '2-digit'
                                          }).format(new Date(note.created_at))
                                        : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {note.file_name && note.upload_finished_at ? (
                                        <IconButton color="success" onClick={() => handleDownloadFile(Number(note.id))}>
                                            <KTSVG
                                                path="/media/icons/duotune/files/fil017.svg"
                                                className="svg-icon-3"
                                            />
                                        </IconButton>
                                    ) : (
                                        'N/A'
                                    )}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={(e) => {
                                            setNote(note);
                                            handleClick(e);
                                        }}
                                    >
                                        <MoreVertRounded />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={notesAndLinks.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[10, 25, 30]}
                />
            </TableWrapper>
            <AddNoteModal
                note={note}
                setNote={setNote}
                open={addNotesOpen}
                setOpen={setAddNotesOpen}
                vehicle={vehicle}
            />
            <DeleteNoteModal
                open={deleteNotesModalOpen}
                setOpen={setDeleteNotesModalOpen}
                note={note}
                setNote={setNote}
            />
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        setAddNotesOpen(true);
                        handleClose();
                    }}
                >
                    View / Update Note
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setDeleteNotesModalOpen(true);
                        handleClose();
                    }}
                >
                    Delete Note
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default NotesTable;
