import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IPmProgram } from '../../interfaces/PreventableMaintenance/pm.program';

export const pmProgramApi = createApi({
    reducerPath: 'pm_program',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/auth/api/preventable-maintenance/pm-programs`
    }),
    tagTypes: ['PmPrograms'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints(build) {
        return {
            getPmPrograms: build.query<IPmProgram[], void>({
                query: () => ({
                    url: '/',
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                providesTags: [{ type: 'PmPrograms', id: 'PmPrograms' }]
            }),
            getPmProgram: build.query<IPmProgram, number>({
                query: (id) => ({
                    url: `/${id}/`,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                providesTags: [{ type: 'PmPrograms', id: 'PmPrograms' }]
            }),
            createPmProgram: build.mutation<IPmProgram, Partial<IPmProgram>>({
                query: (body) => ({
                    url: '/',
                    method: 'POST',
                    body,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                invalidatesTags: [{ type: 'PmPrograms', id: 'PmPrograms' }]
            }),
            updatePmProgram: build.mutation<IPmProgram, IPmProgram>({
                query: (body) => ({
                    url: `/${body.id}/`,
                    method: 'PUT',
                    body,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                invalidatesTags: [{ type: 'PmPrograms', id: 'PmPrograms' }]
            }),
            deletePmProgram: build.mutation<void, number>({
                query: (id) => ({
                    url: `/${id}/`,
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                invalidatesTags: [{ type: 'PmPrograms', id: 'PmPrograms' }]
            }),
            getPmProgramByVehicle: build.query<IPmProgram[], number | void>({
                query: (id) => ({
                    url: `/get-by-vehicle?vehicle_id=${id}`,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                providesTags: [{ type: 'PmPrograms', id: 'PmPrograms' }]
            }),
            getPmProgramsByCompany: build.query<IPmProgram[], number | void>({
                query: (id) => ({
                    url: `/get-by-company?company_id=${id}`,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                providesTags: [{ type: 'PmPrograms', id: 'PmPrograms' }]
            })
        };
    }
});

export const {
    useGetPmProgramsQuery,
    useGetPmProgramQuery,
    useCreatePmProgramMutation,
    useUpdatePmProgramMutation,
    useDeletePmProgramMutation,
    useGetPmProgramByVehicleQuery,
    useGetPmProgramsByCompanyQuery
} = pmProgramApi;
