import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    useCreateFTADachInfoMutation,
    useUpdateFTADachInfoMutation
} from '../../../store/reducers/EmploymentHistory/employer-information';
import {
    IFTADachInfo,
    IFTADachInfoDto
} from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/previous-employer-information';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useThemeMode } from '../../partials';
import { IMember } from '../../../models';

interface IProps {
    member?: IMember;
    data?: IFTADachInfo;
    employer_information: number;
}

const initialValues: Partial<IFTADachInfoDto> = {
    drug_and_alcohol_information: ''
};

const validationSchema = Yup.object({
    drug_and_alcohol_information: Yup.string().required('Required')
});

const FtaDachInfo = ({ employer_information, data, member }: IProps) => {
    const { mode } = useThemeMode();
    // const { currentUser } = useAuth()
    const [createFtaDach, { isLoading }] = useCreateFTADachInfoMutation();
    const [updateFtaDach] = useUpdateFTADachInfoMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            if (data) {
                await updateFtaDach({
                    ...data,
                    ...values
                });
            } else {
                await createFtaDach({
                    ...values,
                    // @ts-ignore
                    employer_information
                });
            }
        }
    });

    React.useEffect(() => {
        if (data) {
            formik.setValues({
                drug_and_alcohol_information: data.drug_and_alcohol_information
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h5"
                gutterBottom
                component="h4"
            >
                Drug and Alcohol Information (FTA Company)
            </Typography>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    ></Typography>
                    <TextField
                        fullWidth
                        id="drug_and_alcohol_information"
                        size="small"
                        variant="outlined"
                        value={formik.values.drug_and_alcohol_information}
                        multiline
                        rows={4}
                        placeholder="Drug and Alcohol Information (FTA Company)"
                        onChange={(e) => {
                            formik.setFieldValue('drug_and_alcohol_information', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.drug_and_alcohol_information &&
                                        Boolean(formik.errors.drug_and_alcohol_information)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.drug_and_alcohol_information &&
                                        Boolean(formik.errors.drug_and_alcohol_information)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.drug_and_alcohol_information &&
                                        Boolean(formik.errors.drug_and_alcohol_information)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        fullWidth
                        disabled={isLoading || !!member?.terminated_at}
                        onClick={() => formik.handleSubmit()}
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default FtaDachInfo;
