import { TableCell } from '@mui/material';
import React, { FC, useEffect } from 'react';

interface IProps {
    date: Date | string;
}

function formatDateToMMDDYYYY(dateString: string): string {
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
}

const DateComponent: FC<IProps> = ({ date }) => {
    const [formattedDate, setFormattedDate] = React.useState<string>('');

    useEffect(() => {
        if (typeof date === 'string') {
            setFormattedDate(formatDateToMMDDYYYY(date));
            return;
        }
        const utcDate = new Date(date);
        const offsetMinutes = utcDate.getTimezoneOffset();
        utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);
        const year = utcDate.getFullYear();
        const month = utcDate.getMonth();
        const day = utcDate.getDate();
        const formattedDate = `${month}-${day}-${year}`;
        setFormattedDate(formattedDate);
    }, [date]);

    return <TableCell>{formattedDate}</TableCell>;
};

export default DateComponent;
