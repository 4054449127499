import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useCompany } from '../../../context/CompanyContext';
import VehicleList from './VehicleList';
import MaintenanceMemberTable from './MaintenanceMemberTable';
import ServiceAlertsTable from './ServiceAlertsTable';
import { useAuth } from '../../modules/auth';
import { ICompany } from '../../../models';
import { useParams } from 'react-router-dom';
import { useGetServiceAlertsByCompanyQuery } from '../../../store/reducers/PreventableMaintenance/service.alerts';
import OwnerDVIReportTable from './OwnerDVIReportTable';

const PreventableMaintenanceContainer = () => {
    const { id } = useParams<{ id: string }>();
    const [company, setCompany] = React.useState<ICompany>();
    const { currentCompany } = useCompany();
    const { getCompanies } = useAuth();
    const [companies, setCompanies] = React.useState<ICompany[]>([]);
    const { data = [] } = useGetServiceAlertsByCompanyQuery(Number(company?.id) || Number(currentCompany?.id), {
        skip: !company?.id && !currentCompany?.id,
        refetchOnFocus: true,
        pollingInterval: 5000
    });
    const fetchCompanies = () => {
        getCompanies().then((res: ICompany[]) => {
            setCompanies(res);
        });
    };

    useEffect(() => {
        fetchCompanies();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (companies.length > 0) {
            const companyRes = companies.find((company) => Number(company.id) === Number(id));
            setCompany(companyRes);
        }
        // eslint-disable-next-line
    }, [companies]);

    return (
        <>
            <Typography variant="h5">
                {currentCompany?.name || company?.name} Preventable Maintenance Program
            </Typography>
            <VehicleList />
            <MaintenanceMemberTable />
            <ServiceAlertsTable serviceAlerts={data} />
            <OwnerDVIReportTable />
        </>
    );
};

export default PreventableMaintenanceContainer;
