import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IDriverReleaseForm, IDriverReleaseFormDto } from '../../interfaces/DriverReleaseForm/driver-release-form';
import { IProgress } from '../../interfaces/abstract';

export const driverReleaseFormApi = createApi({
    reducerPath: 'driver_release_form',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/drivers-release-form`
    }),
    tagTypes: ['DriverReleaseForm'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createDriverReleaseForm: builder.mutation<IDriverReleaseForm, IDriverReleaseFormDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriverReleaseForm', id: 'DriverReleaseForm' }]
        }),
        updateDriverReleaseForm: builder.mutation<IDriverReleaseForm, IDriverReleaseForm>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriverReleaseForm', id: 'DriverReleaseForm' }]
        }),
        deleteDriverReleaseForm: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriverReleaseForm', id: 'DriverReleaseForm' }]
        }),
        getDriverReleaseForm: builder.query<IDriverReleaseForm, number>({
            query: (id) => ({
                url: `/get-drivers-release-form/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverReleaseForm', id: 'DriverReleaseForm' }]
        }),
        getDriverReleaseFormByUser: builder.query<IDriverReleaseForm, void>({
            query: (id) => ({
                url: `/get-drivers-release-form-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverReleaseForm', id: 'DriverReleaseForm' }]
        }),
        getDriverReleaseFormByUserId: builder.query<IDriverReleaseForm, number>({
            query: (id) => ({
                url: `/get-drivers-release-form-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverReleaseForm', id: 'DriverReleaseForm' }]
        }),
        getMemberDriverReleaseFormProgress: builder.query<IProgress, number>({
            query: (id) => ({
                url: `/get-total-progress/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverReleaseForm', id: 'DriverReleaseForm' }]
        }),
        getDriverReleaseFormProgress: builder.query<IProgress, number>({
            query: (id) => ({
                url: `/get-member-progress/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverReleaseForm', id: 'DriverReleaseForm' }]
        })
    })
});

export const {
    useGetDriverReleaseFormQuery,
    useCreateDriverReleaseFormMutation,
    useUpdateDriverReleaseFormMutation,
    useGetDriverReleaseFormByUserQuery,
    useGetDriverReleaseFormByUserIdQuery,
    useGetDriverReleaseFormProgressQuery,
    useGetMemberDriverReleaseFormProgressQuery,
    useDeleteDriverReleaseFormMutation
} = driverReleaseFormApi;
