import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IAuthorizedEmployerRepresentativeSignature,
    IAuthorizedEmployerRepresentativeSignatureDto
} from '../../interfaces/DrugandAlcoholEmployeesCertifiedReceipt/authorized-employer-representative-signature';

export const authorizedEmployerRepresentativeSignatureApi = createApi({
    reducerPath: 'authorized_employer_representative_signature',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/authorized-employer-representative-signature`
    }),
    tagTypes: ['AuthorizedEmployerRepresentativeSignature'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createAuthorizedEmployerRepresentativeSignature: builder.mutation<
            IAuthorizedEmployerRepresentativeSignature,
            IAuthorizedEmployerRepresentativeSignatureDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'AuthorizedEmployerRepresentativeSignature',
                    id: 'AuthorizedEmployerRepresentativeSignature'
                }
            ]
        }),
        updateAuthorizedEmployerRepresentativeSignature: builder.mutation<
            IAuthorizedEmployerRepresentativeSignature,
            IAuthorizedEmployerRepresentativeSignature
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'AuthorizedEmployerRepresentativeSignature',
                    id: 'AuthorizedEmployerRepresentativeSignature'
                }
            ]
        }),
        getAuthorizedEmployerRepresentativeSignature: builder.query<
            IAuthorizedEmployerRepresentativeSignature,
            number | void
        >({
            query: (id) => ({
                url: `/get-authorized-employer-representative-signature/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'AuthorizedEmployerRepresentativeSignature',
                    id: 'AuthorizedEmployerRepresentativeSignature'
                }
            ]
        }),
        getAuthorizedEmployerRepresentativeSignatureByUser: builder.query<
            IAuthorizedEmployerRepresentativeSignature,
            void
        >({
            query: () => ({
                url: `/get-authorized-employer-representative-signature-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'AuthorizedEmployerRepresentativeSignature',
                    id: 'AuthorizedEmployerRepresentativeSignature'
                }
            ]
        }),
        getAuthorizedEmployerRepresentativeSignatureByUserId: builder.query<
            IAuthorizedEmployerRepresentativeSignature,
            number
        >({
            query: (id) => ({
                url: `/get-authorized-employer-representative-signature-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'AuthorizedEmployerRepresentativeSignature',
                    id: 'AuthorizedEmployerRepresentativeSignature'
                }
            ]
        }),
        deleteAuthorizedEmployerRepresentativeSignature: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'AuthorizedEmployerRepresentativeSignature',
                    id: 'AuthorizedEmployerRepresentativeSignature'
                }
            ]
        })
    })
});

export const {
    useCreateAuthorizedEmployerRepresentativeSignatureMutation,
    useUpdateAuthorizedEmployerRepresentativeSignatureMutation,
    useGetAuthorizedEmployerRepresentativeSignatureQuery,
    useGetAuthorizedEmployerRepresentativeSignatureByUserQuery,
    useGetAuthorizedEmployerRepresentativeSignatureByUserIdQuery,
    useDeleteAuthorizedEmployerRepresentativeSignatureMutation
} = authorizedEmployerRepresentativeSignatureApi;
