import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { IDVIReport, Vehicle } from '../../../store/interfaces/PreventableMaintenance/dvir.report';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    useCreateVehicleMutation,
    useGetVehicleByReportQuery,
    useUpdateVehicleMutation
} from '../../../store/reducers/PreventableMaintenance/dvir.report';

const initialValues: Partial<Vehicle> = {
    report: undefined,
    air_compressor: false,
    air_lines: false,
    battery: false,
    belts_and_hoses: false,
    body: false,
    brake_accessories: false,
    brakes_parking: false,
    brakes_service: false,
    cluth: false,
    coupling_devices: false,
    defroster_heater: false,
    drive_line: false,
    engine: false,
    exhaust: false,
    emergency_exits: false,
    fifth_wheel: false,
    fire_extinguisher: false,
    flags_flares_fuses: false,
    fluid_levels: false,
    frame_and_assembly: false,
    front_axle: false,
    fuel_tanks: false,
    horn: false,
    lights: {
        head_stop: false,
        tail_dash: false,
        turn_indicators: false,
        clearance_marker: false
    },
    mirrors: false,
    muffler: false,
    oil_pressure: false,
    radiator: false,
    rear_end: false,
    reflectors: false,
    reflective_triangles: false,
    safety_equipment: false,
    spare_bulbs_fuses: false,
    spare_seal_beam: false,
    starter: false,
    steering: false,
    suspension_system: false,
    tires: false,
    tire_chains: false,
    transmission: false,
    trip_recorder: false,
    wheels_and_rims: false,
    windows: false,
    windshield_wipers: false,
    others: ''
};

interface IProps {
    dviReport?: IDVIReport;
    createReport?: boolean;
    disabled?: boolean;
    conditionSatisfactory?: boolean;
    setDefectChecked?: (value: boolean) => void;
}

const TruckCheckList: React.FunctionComponent<IProps> = ({
    dviReport,
    createReport,
    disabled,
    conditionSatisfactory,
    setDefectChecked
}) => {
    const [createVehicleReport, { data, isSuccess }] = useCreateVehicleMutation();
    const [updateVehicleReport, { data: updated, isSuccess: updateSuccess }] = useUpdateVehicleMutation();
    const { data: vehicle } = useGetVehicleByReportQuery(dviReport?.id || 0, {
        refetchOnFocus: true,
        skip: !dviReport?.id
    });

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({}),
        onSubmit: (values) => {
            if (vehicle) {
                updateVehicleReport({
                    ...values,
                    id: vehicle.id,
                    report: dviReport
                });
            } else {
                createVehicleReport({
                    ...values,
                    report: dviReport
                });
            }
        }
    });

    useEffect(() => {
        if (dviReport && createReport) {
            formik.handleSubmit();
        }
        // eslint-disable-next-line
    }, [dviReport]);

    useEffect(() => {
        if (isSuccess && data) {
            formik.setValues(data);
        }
        // eslint-disable-next-line
    }, [data, isSuccess]);

    useEffect(() => {
        if (vehicle) {
            formik.setValues(vehicle);
        }
        if (updateSuccess && updated) {
            formik.setValues(updated);
        }
        // eslint-disable-next-line
    }, [vehicle, updated]);

    useEffect(() => {
        const values = Object.values(formik.values);
        if (values.includes(true)) {
            setDefectChecked && setDefectChecked(true);
        } else {
            setDefectChecked && setDefectChecked(false);
        }
        // eslint-disable-next-line
    }, [formik.values]);

    return (
        <>
            {!conditionSatisfactory && (
                <>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
                            Truck / Tractor / Vehicle No.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.air_compressor}
                                    onClick={() =>
                                        formik.setFieldValue('air_compressor', !formik.values.air_compressor)
                                    }
                                />
                            }
                            label="Air Compressor"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.air_lines}
                                    onClick={() => formik.setFieldValue('air_lines', !formik.values.air_lines)}
                                />
                            }
                            label="Air Lines"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.battery}
                                    onClick={() => formik.setFieldValue('battery', !formik.values.battery)}
                                />
                            }
                            label="Battery"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.belts_and_hoses}
                                    onClick={() =>
                                        formik.setFieldValue('belts_and_hoses', !formik.values.belts_and_hoses)
                                    }
                                />
                            }
                            label="Belts & Hoses"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.body}
                                    onClick={() => formik.setFieldValue('body', !formik.values.body)}
                                />
                            }
                            label="Body"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.brake_accessories}
                                    onClick={() =>
                                        formik.setFieldValue('brake_accessories', !formik.values.brake_accessories)
                                    }
                                />
                            }
                            label="Brake Accessories"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.brakes_parking}
                                    onClick={() =>
                                        formik.setFieldValue('brakes_parking', !formik.values.brakes_parking)
                                    }
                                />
                            }
                            label="Brakes, Parking"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.brakes_service}
                                    onClick={() =>
                                        formik.setFieldValue('brakes_service', !formik.values.brakes_service)
                                    }
                                />
                            }
                            label="Brakes, Service"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.cluth}
                                    onClick={() => formik.setFieldValue('cluth', !formik.values.cluth)}
                                />
                            }
                            label="Cluth"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.coupling_devices}
                                    onClick={() =>
                                        formik.setFieldValue('coupling_devices', !formik.values.coupling_devices)
                                    }
                                />
                            }
                            label="Coupling Devices"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.defroster_heater}
                                    onClick={() =>
                                        formik.setFieldValue('defroster_heater', !formik.values.defroster_heater)
                                    }
                                />
                            }
                            label="Defroster / Heater"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.drive_line}
                                    onClick={() => formik.setFieldValue('drive_line', !formik.values.drive_line)}
                                />
                            }
                            label="Drive Line"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.engine}
                                    onClick={() => formik.setFieldValue('engine', !formik.values.engine)}
                                />
                            }
                            label="Engine"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.exhaust}
                                    onClick={() => formik.setFieldValue('exhaust', !formik.values.exhaust)}
                                />
                            }
                            label="Exhaust"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.emergency_exits}
                                    onClick={() =>
                                        formik.setFieldValue('emergency_exits', !formik.values.emergency_exits)
                                    }
                                />
                            }
                            label="Emergency Exits"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.fifth_wheel}
                                    onClick={() => formik.setFieldValue('fifth_wheel', !formik.values.fifth_wheel)}
                                />
                            }
                            label="Fifth Wheel"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.fire_extinguisher}
                                    onClick={() =>
                                        formik.setFieldValue('fire_extinguisher', !formik.values.fire_extinguisher)
                                    }
                                />
                            }
                            label="Fire Extinguisher"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.flags_flares_fuses}
                                    onClick={() =>
                                        formik.setFieldValue('flags_flares_fuses', !formik.values.flags_flares_fuses)
                                    }
                                />
                            }
                            label="Flags / Flares / Fuses"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.fluid_levels}
                                    onClick={() => formik.setFieldValue('fluid_levels', !formik.values.fluid_levels)}
                                />
                            }
                            label="Fluid Levels"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.frame_and_assembly}
                                    onClick={() =>
                                        formik.setFieldValue('frame_and_assembly', !formik.values.frame_and_assembly)
                                    }
                                />
                            }
                            label="Frame and Assembly"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.front_axle}
                                    onClick={() => formik.setFieldValue('front_axle', !formik.values.front_axle)}
                                />
                            }
                            label="Front Axle"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.fuel_tanks}
                                    onClick={() => formik.setFieldValue('fuel_tanks', !formik.values.fuel_tanks)}
                                />
                            }
                            label="Fuel Tanks"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.horn}
                                    onClick={() => formik.setFieldValue('horn', !formik.values.horn)}
                                />
                            }
                            label="Horn"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        formik.values.lights?.clearance_marker ||
                                        formik.values.lights?.head_stop ||
                                        formik.values.lights?.tail_dash ||
                                        formik.values.lights?.turn_indicators
                                    }
                                    disabled={disabled}
                                />
                            }
                            label="Lights"
                            disabled={disabled}
                        />
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '75%',
                                        md: '60%',
                                        lg: '60%'
                                    }
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.lights?.head_stop}
                                            onClick={() =>
                                                formik.setFieldValue(
                                                    'lights.head_stop',
                                                    !formik.values.lights?.head_stop
                                                )
                                            }
                                        />
                                    }
                                    label="Head Stop"
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '75%',
                                        md: '60%',
                                        lg: '60%'
                                    }
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.lights?.tail_dash}
                                            onClick={() =>
                                                formik.setFieldValue(
                                                    'lights.tail_dash',
                                                    !formik.values.lights?.tail_dash
                                                )
                                            }
                                        />
                                    }
                                    label="Tail Dash"
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '75%',
                                        md: '60%',
                                        lg: '60%'
                                    }
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.lights?.turn_indicators}
                                            onClick={() =>
                                                formik.setFieldValue(
                                                    'lights.turn_indicators',
                                                    !formik.values.lights?.turn_indicators
                                                )
                                            }
                                        />
                                    }
                                    label="Turn Indicators"
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: '75%',
                                        md: '60%',
                                        lg: '60%'
                                    }
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.lights?.clearance_marker}
                                            onClick={() =>
                                                formik.setFieldValue(
                                                    'lights.clearance_marker',
                                                    !formik.values.lights?.clearance_marker
                                                )
                                            }
                                        />
                                    }
                                    label="Clearance Marker"
                                    disabled={disabled}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.mirrors}
                                    onClick={() => formik.setFieldValue('mirrors', !formik.values.mirrors)}
                                />
                            }
                            label="Mirrors"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.muffler}
                                    onClick={() => formik.setFieldValue('muffler', !formik.values.muffler)}
                                />
                            }
                            label="Muffler"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.oil_pressure}
                                    onClick={() => formik.setFieldValue('oil_pressure', !formik.values.oil_pressure)}
                                />
                            }
                            label="Oil Pressure"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.radiator}
                                    onClick={() => formik.setFieldValue('radiator', !formik.values.radiator)}
                                />
                            }
                            label="Radiator"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.rear_end}
                                    onClick={() => formik.setFieldValue('rear_end', !formik.values.rear_end)}
                                />
                            }
                            label="Rear End"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.reflectors}
                                    onClick={() => formik.setFieldValue('reflectors', !formik.values.reflectors)}
                                />
                            }
                            label="Reflectors"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.reflective_triangles}
                                    onClick={() =>
                                        formik.setFieldValue(
                                            'reflective_triangles',
                                            !formik.values.reflective_triangles
                                        )
                                    }
                                />
                            }
                            label="Reflective Triangles"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.safety_equipment}
                                    onClick={() =>
                                        formik.setFieldValue('safety_equipment', !formik.values.safety_equipment)
                                    }
                                />
                            }
                            label="Safety Equipment"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.spare_bulbs_fuses}
                                    onClick={() =>
                                        formik.setFieldValue('spare_bulbs_fuses', !formik.values.spare_bulbs_fuses)
                                    }
                                />
                            }
                            label="Spare Bulbs & Fuses"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.spare_seal_beam}
                                    onClick={() =>
                                        formik.setFieldValue('spare_seal_beam', !formik.values.spare_seal_beam)
                                    }
                                />
                            }
                            label="Spare Seal Beam"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.starter}
                                    onClick={() => formik.setFieldValue('starter', !formik.values.starter)}
                                />
                            }
                            label="Starter"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.steering}
                                    onClick={() => formik.setFieldValue('steering', !formik.values.steering)}
                                />
                            }
                            label="Steering"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.suspension_system}
                                    onClick={() =>
                                        formik.setFieldValue('suspension_system', !formik.values.suspension_system)
                                    }
                                />
                            }
                            label="Suspenion System"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.tires}
                                    onClick={() => formik.setFieldValue('tires', !formik.values.tires)}
                                />
                            }
                            label="Tire Chains"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.tires}
                                    onClick={() => formik.setFieldValue('tires', !formik.values.tires)}
                                />
                            }
                            label="Tires"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.transmission}
                                    onClick={() => formik.setFieldValue('transmission', !formik.values.transmission)}
                                />
                            }
                            label="Transmission"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.trip_recorder}
                                    onClick={() => formik.setFieldValue('trip_recorder', !formik.values.trip_recorder)}
                                />
                            }
                            label="Trip Recorder"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.wheels_and_rims}
                                    onClick={() =>
                                        formik.setFieldValue('wheels_and_rims', !formik.values.wheels_and_rims)
                                    }
                                />
                            }
                            label="Wheels and Rims"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.windows}
                                    onClick={() => formik.setFieldValue('windows', !formik.values.windows)}
                                />
                            }
                            label="Windows"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.windshield_wipers}
                                    onClick={() =>
                                        formik.setFieldValue('windshield_wipers', !formik.values.windshield_wipers)
                                    }
                                />
                            }
                            label="Windshield Wipers"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(formik.values.others)}
                                    onClick={() => formik.setFieldValue('others', !formik.values.others)}
                                />
                            }
                            label="Other"
                            disabled={disabled}
                        />
                    </Grid>
                </>
            )}
        </>
    );
};

export default TruckCheckList;
