import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IProspectiveEmployerInformationDto,
    IProspectiveEmployerInformation
} from '../../interfaces/PreviousEmployeeSafetyPerformanceHistory/prospective-employer-information';

export const prospectiveEmployerInformationApi = createApi({
    reducerPath: 'prospective_employer_information',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/prospective-employer-information`
    }),
    tagTypes: ['ProspectiveEmployerInformation'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createProspectiveEmployerInformation: builder.mutation<
            IProspectiveEmployerInformation,
            IProspectiveEmployerInformationDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ProspectiveEmployerInformation', id: 'ProspectiveEmployerInformation' }]
        }),
        updateProspectiveEmployerInformation: builder.mutation<
            IProspectiveEmployerInformation,
            IProspectiveEmployerInformation
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ProspectiveEmployerInformation', id: 'ProspectiveEmployerInformation' }]
        }),
        getProspectiveEmployerInformation: builder.query<IProspectiveEmployerInformation, number | void>({
            query: (id) => ({
                url: `/get-prospective-employer-information/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ProspectiveEmployerInformation', id: 'ProspectiveEmployerInformation' }]
        }),
        getProspectiveEmployerInformationByUser: builder.query<IProspectiveEmployerInformation, void>({
            query: () => ({
                url: `/get-prospective-employer-information-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ProspectiveEmployerInformation', id: 'ProspectiveEmployerInformation' }]
        }),
        getProspectiveEmployerInformationByUserId: builder.query<IProspectiveEmployerInformation, number | void>({
            query: (id) => ({
                url: `/get-prospective-employer-information-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ProspectiveEmployerInformation', id: 'ProspectiveEmployerInformation' }]
        }),
        deleteProspectiveEmployerInformation: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'ProspectiveEmployerInformation',
                    id: 'ProspectiveEmployerInformation'
                }
            ]
        })
    })
});

export const {
    useCreateProspectiveEmployerInformationMutation,
    useUpdateProspectiveEmployerInformationMutation,
    useGetProspectiveEmployerInformationQuery,
    useGetProspectiveEmployerInformationByUserQuery,
    useGetProspectiveEmployerInformationByUserIdQuery,
    useDeleteProspectiveEmployerInformationMutation
} = prospectiveEmployerInformationApi;
