import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    OperatingInTrafficPassingAndTurning,
    OperatingInTrafficPassingAndTurningDto
} from '../../interfaces/RecordOfRoadTest/operating-in-traffic-passing-and-turning';

export const operatingInTrafficPassingAndTurningApi = createApi({
    reducerPath: 'operating_in_traffic_passing_and_turning',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/operating-in-traffic-passing-and-turning`
    }),
    tagTypes: ['OperatingInTrafficPassingAndTurning'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createOperatingInTrafficPassingAndTurning: builder.mutation<
            OperatingInTrafficPassingAndTurning,
            OperatingInTrafficPassingAndTurningDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'OperatingInTrafficPassingAndTurning', id: 'OperatingInTrafficPassingAndTurning' }
            ]
        }),
        updateOperatingInTrafficPassingAndTurning: builder.mutation<
            OperatingInTrafficPassingAndTurning,
            OperatingInTrafficPassingAndTurning
        >({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'OperatingInTrafficPassingAndTurning', id: 'OperatingInTrafficPassingAndTurning' }
            ]
        }),
        deleteOperatingInTrafficPassingAndTurning: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                { type: 'OperatingInTrafficPassingAndTurning', id: 'OperatingInTrafficPassingAndTurning' }
            ]
        }),
        getOperatingInTrafficPassingAndTurning: builder.query<OperatingInTrafficPassingAndTurning, number | void>({
            query: (id) => ({
                url: `/get-operating-in-traffic-passing-and-turning/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'OperatingInTrafficPassingAndTurning', id: 'OperatingInTrafficPassingAndTurning' }]
        }),
        getOperatingInTrafficPassingAndTurningByUser: builder.query<OperatingInTrafficPassingAndTurning, void>({
            query: () => ({
                url: `/get-operating-in-traffic-passing-and-turning-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'OperatingInTrafficPassingAndTurning', id: 'OperatingInTrafficPassingAndTurning' }]
        }),
        getOperatingInTrafficPassingAndTurningByUserId: builder.query<
            OperatingInTrafficPassingAndTurning,
            number | void
        >({
            query: (id) => ({
                url: `/get-operating-in-traffic-passing-and-turning-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'OperatingInTrafficPassingAndTurning', id: 'OperatingInTrafficPassingAndTurning' }]
        })
    })
});

export const {
    useCreateOperatingInTrafficPassingAndTurningMutation,
    useUpdateOperatingInTrafficPassingAndTurningMutation,
    useGetOperatingInTrafficPassingAndTurningQuery,
    useGetOperatingInTrafficPassingAndTurningByUserQuery,
    useGetOperatingInTrafficPassingAndTurningByUserIdQuery,
    useDeleteOperatingInTrafficPassingAndTurningMutation
} = operatingInTrafficPassingAndTurningApi;
