import React, { useEffect, useMemo, useState } from 'react';
import VehicleProfile from './VehicleProfile';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';
import PMProgram from './PMProgram';
import { useGetMaintenanceVehicleQuery } from '../../../../store/reducers/PreventableMaintenance/maintenance.vehicle';
import DriverVehicleInspectionReport from './DriverVehicleInspectionReport';
import { useGetServiceTypesByProgramIdQuery } from '../../../../store/reducers/PreventableMaintenance/service.types';
import ServiceOrders from './ServiceOrders';
import NotesAndLinks from './NotesAndLinks';
import { IDVIReport } from '../../../../store/interfaces/PreventableMaintenance/dvir.report';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';

const getValidDate = (dateString: string) => {
    const timestamp = Date.parse(dateString);
    return isNaN(timestamp) ? null : timestamp;
};

const useDVIRReports = (vehicle?: IMaintenanceVehicle) => {
    const [reports, setReports] = useState<IDVIReport[]>([]);
    const [error, setError] = useState<string>();

    // Memoize the combined and sorted reports
    const sortedReports = useMemo(() => {
        try {
            const dvirReports = vehicle?.dvir_reports ?? [];
            const dvirReportsTrailer = vehicle?.dvir_report_trailer ?? [];

            return [...dvirReports, ...dvirReportsTrailer]
                .filter((report) => {
                    const timestamp = getValidDate(report.created_at);
                    if (timestamp === null) {
                        console.warn(`Invalid date found for report ID: ${report.id}`);
                        return false;
                    }
                    return true;
                })
                .sort((a, b) => {
                    const dateA = getValidDate(a.created_at);
                    const dateB = getValidDate(b.created_at);
                    if (dateA === null && dateB === null) return 0;
                    if (dateA === null) return 1;
                    if (dateB === null) return -1;

                    return dateA - dateB;
                });
        } catch (err: any) {
            setError('Error processing reports: ' + err.message);
            return [];
        }
    }, [vehicle?.dvir_reports, vehicle?.dvir_report_trailer]);

    useEffect(() => {
        if (error) {
            // Log error or send to error reporting service
            console.error(error);
        }

        // Update reports state
        setReports(sortedReports);
    }, [sortedReports, error]);

    return {
        reports,
        error
    };
};

const MaintenanceVehicleDetails = () => {
    const { id } = useParams<{ id: string }>();

    const { data: vehicle, refetch } = useGetMaintenanceVehicleQuery(Number(id), {
        refetchOnFocus: true,
        skip: !id
    });

    const { reports: dvirReports } = useDVIRReports(vehicle);

    const { data: service_types = [] } = useGetServiceTypesByProgramIdQuery(Number(vehicle?.pm_program?.id ?? 0), {
        refetchOnFocus: true,
        skip: !vehicle?.pm_program?.id
    });

    const onCreate = () => {
        refetch();
    };

    return (
        <Routes>
            <Route
                element={
                    <>
                        <VehicleProfile vehicle={vehicle} />
                        <Outlet />
                    </>
                }
            >
                <Route
                    path="pm-program"
                    element={<PMProgram onCreate={onCreate} service_types={service_types} vehicle={vehicle} />}
                />
                <Route
                    path="driver-vehicle-inspection-report"
                    element={<DriverVehicleInspectionReport dvirList={dvirReports} />}
                />
                <Route path="service-orders" element={<ServiceOrders vehicle={vehicle} />} />
                <Route
                    path="notes-and-links"
                    element={<NotesAndLinks vehicle={vehicle} notesAndLinks={vehicle?.notes_and_links} />}
                />
                <Route index element={<Navigate to="pm-program" />} />
                <Route path="*" element={<h1>Not Found</h1>} />
            </Route>
        </Routes>
    );
};

export default MaintenanceVehicleDetails;
