import { Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Slide, TextField } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, FunctionComponent, ReactElement, Ref, useEffect } from 'react';
import { ICustomProducts } from '../../../store/interfaces/PreventableMaintenance/stripe';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { StripeActions } from '../../../store/reducers/StripeProducts/StripeActions';
import { useSelector } from '../../../store';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues: ICustomProducts = {
    name: '',
    description: '',
    price: 0,
    parent_product_id: '',
    parent_product_name: ''
};

const validationSchema = yup.object().shape({
    name: yup.string().required('Product name is required'),
    description: yup.string().required('Description is required'),
    price: yup.number().required('Price is required'),
    parent_product_id: yup.string().required('Parent product required'),
    parent_product_name: yup.string().required('Parent product required')
});

export const AddProductModal: FunctionComponent<IProps> = ({ open, setOpen }) => {
    const { products } = useSelector((state) => state.stripeReducer.product);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            await StripeActions.createCustomProduct(values);
            formik.resetForm();
            setOpen(false);
        }
    });

    useEffect(() => {
        if (!open) {
            formik.resetForm();
        }
        if (open) {
            StripeActions.getProducts();
        }
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{ textAlign: 'center' }}>Add Stripe Product</DialogTitle>
            <DialogContent>
                <Grid container spacing={3} p={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="name"
                            label="Product Name"
                            size="small"
                            fullWidth
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="price"
                            label="Product Price"
                            size="small"
                            type="number"
                            fullWidth
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            error={formik.touched.price && Boolean(formik.errors.price)}
                            helperText={formik.touched.price && formik.errors.price}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="parent_product_id"
                            label="Parent Product"
                            size="small"
                            fullWidth
                            value={formik.values.parent_product_id}
                            onChange={(e) => {
                                const product = products.find((product) => product.product_id === e.target.value);
                                formik.setFieldValue('parent_product_id', e.target.value);
                                formik.setFieldValue('parent_product_name', product?.name);
                            }}
                            error={formik.touched.parent_product_id && Boolean(formik.errors.parent_product_id)}
                            helperText={formik.touched.parent_product_id && formik.errors.parent_product_id}
                            select
                        >
                            {products.map((product) => (
                                <MenuItem key={product.product_id} value={product.product_id}>
                                    {product.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="description"
                            label="Product Description"
                            size="small"
                            fullWidth
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            size="small"
                            fullWidth
                            color="primary"
                            onClick={() => formik.handleSubmit()}
                        >
                            Add Product
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
