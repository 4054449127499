import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Middleware, MiddlewareAPI } from 'redux';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import { stripeSlice } from './reducers/StripeProducts/StripeReducer';
import {
    PerformanceHistoryMiddleware,
    PerformanceHistoryReducers
} from './reducers/PreviousEmployeeSafetyPerformanceHistory/index';
import {
    PreviousPreEmploymentDrugAndAlcoholTestStatementMiddleware,
    PreviousPreEmploymentDrugAndAlcoholTestStatementReducers
} from './reducers/PreviousPreEmploymentDrugAndAlcoholTestStatement';
import { DriversApplicationMiddleware, DriversApplicationReducers } from './reducers/DriversApplicationForEmployment';
import {
    AnnualReviewOfDrivingRecordMiddleware,
    AnnualReviewOfDrivingRecordReducers
} from './reducers/AnnualReviewOfDrivingRecord';
import {
    CertificationMiddleware,
    CertificationReducers
} from './reducers/CertificateOfComplianceWithDriverLicenseRequirements';
import { CertificateOfRoadTestMiddleware, CertificateOfRoadTestReducers } from './reducers/CertificateOfRoadTest';
import {
    DocsOfActualKnowledgeMiddleware,
    DocsOfActualKnowledgeReducers
} from './reducers/DocumentationofActualKnowledgeofDOTDAndAViolation';
import {
    DriverMedicalExaminerRegistryVerificationMiddleware,
    DriverMedicalExaminerRegistryVerificationReducers
} from './reducers/DriverMedicalExaminerRegistryVerification';
import { DriversHandbookReceiptMiddleware, DriversHandbookReceiptReducers } from './reducers/DriversHandbookReceipt';
import {
    DriverStatementDutyHoursMiddleware,
    DriverStatementDutyHoursReducers
} from './reducers/DriverStatementDutyHours';
import {
    DachEmployeesCertifiedReceiptMiddleware,
    DachEmployeesCertifiedReceiptReducers
} from './reducers/DrugandAlcoholEmployeesCertifiedReceipt';
import { EmploymentHistoryMiddleware, EmploymentHistoryReducers } from './reducers/EmploymentHistory';
import { RecordOfRoadTestMiddleware, RecordOfRoadTestReducers } from './reducers/RecordOfRoadTest';
import {
    RequestForCheckOfDrivingRecordsMiddleware,
    RequestForCheckOfDrivingRecordsReducers
} from './reducers/RequestForCheckOfDrivingRecords';
import {
    LimitedQueriesDrugAndAlcoholClearinghouseMiddleware,
    LimitedQueriesDrugAndAlcoholClearinghouseReducers
} from './reducers/LimitedQueriesDrugAndAlcoholClearinghouse';
import { DriverReleaseFormMiddleware, DriverReleaseFormReducers } from './reducers/DriverReleaseForm';
import {
    ReleaseFormBackgroundCheckMiddleware,
    ReleaseFormBackgroundCheckReducers
} from './reducers/ReleaseFormBackgroundCheck';
import { PSPDisclosureMiddleware, PSPDisclosureReducers } from './reducers/PSPDisclosure';
import {
    DrugAlcoholWrittenPolicyMiddleware,
    DrugAlcoholWrittenPolicyReducers
} from './reducers/DrugAlcoholWrittenPolicy';
import { RandomDachMiddleware, RandomDachReducers } from './reducers/RandomDach';
import {
    DvirReportMiddleware,
    DvirReportReducers,
    MaintenanceMemberMiddleware,
    MaintenanceMemberReducers,
    MaintenanceVehicleMiddleware,
    MaintenanceVehicleReducers,
    NotesAndLinksMiddleware,
    NotesAndLinksReducers,
    PmProgramMiddleware,
    PmProgramReducers,
    ServiceAlertsMiddleware,
    ServiceAlertsReducers,
    ServiceOrdersMiddleware,
    ServiceOrdersReducers,
    ServiceProgramMiddleware,
    ServiceProgramReducers
} from './reducers/PreventableMaintenance';

const rootReducer = combineReducers({
    stripeReducer: stripeSlice.reducer,
    ...PerformanceHistoryReducers,
    ...PreviousPreEmploymentDrugAndAlcoholTestStatementReducers,
    ...DriversApplicationReducers,
    ...AnnualReviewOfDrivingRecordReducers,
    ...CertificationReducers,
    ...CertificateOfRoadTestReducers,
    ...DocsOfActualKnowledgeReducers,
    ...DriverMedicalExaminerRegistryVerificationReducers,
    ...DriversHandbookReceiptReducers,
    ...DriverStatementDutyHoursReducers,
    ...DachEmployeesCertifiedReceiptReducers,
    ...EmploymentHistoryReducers,
    ...RecordOfRoadTestReducers,
    ...RequestForCheckOfDrivingRecordsReducers,
    ...LimitedQueriesDrugAndAlcoholClearinghouseReducers,
    ...DriverReleaseFormReducers,
    ...ReleaseFormBackgroundCheckReducers,
    ...PSPDisclosureReducers,
    ...DrugAlcoholWrittenPolicyReducers,
    ...RandomDachReducers,
    ...ServiceProgramReducers,
    ...MaintenanceMemberReducers,
    ...MaintenanceVehicleReducers,
    ...DvirReportReducers,
    ...PmProgramReducers,
    ...NotesAndLinksReducers,
    ...ServiceOrdersReducers,
    ...ServiceAlertsReducers
});

const unauthorizedMiddleware: Middleware<{}, AppState> = (api: MiddlewareAPI) => (next) => (action) => {
    // Use helper functions like isRejectedWithValue to narrow down action types
    if (isRejectedWithValue(action) && action.payload?.status === 401) {
        // Handle the 401 unauthorized status
        window.localStorage.removeItem('compliance_master_refer_path');
    }

    // Forward the action to the next middleware
    return next(action);
};

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat([
            ...PerformanceHistoryMiddleware,
            ...PreviousPreEmploymentDrugAndAlcoholTestStatementMiddleware,
            ...DriversApplicationMiddleware,
            ...AnnualReviewOfDrivingRecordMiddleware,
            ...CertificationMiddleware,
            ...CertificateOfRoadTestMiddleware,
            ...DocsOfActualKnowledgeMiddleware,
            ...DriverMedicalExaminerRegistryVerificationMiddleware,
            ...DriversHandbookReceiptMiddleware,
            ...DriverStatementDutyHoursMiddleware,
            ...DachEmployeesCertifiedReceiptMiddleware,
            ...EmploymentHistoryMiddleware,
            ...RecordOfRoadTestMiddleware,
            ...RequestForCheckOfDrivingRecordsMiddleware,
            ...LimitedQueriesDrugAndAlcoholClearinghouseMiddleware,
            ...DriverReleaseFormMiddleware,
            ...ReleaseFormBackgroundCheckMiddleware,
            ...PSPDisclosureMiddleware,
            ...DrugAlcoholWrittenPolicyMiddleware,
            ...RandomDachMiddleware,
            ...ServiceProgramMiddleware,
            ...MaintenanceMemberMiddleware,
            ...MaintenanceVehicleMiddleware,
            ...DvirReportMiddleware,
            ...PmProgramMiddleware,
            ...NotesAndLinksMiddleware,
            ...ServiceOrdersMiddleware,
            ...ServiceAlertsMiddleware,
            unauthorizedMiddleware
        ]);
    }
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;
export const useDispatch: () => AppDispatch = useAppDispatch;

setupListeners(store.dispatch);
