import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    ICertification,
    ICertificationDto
} from '../../interfaces/CertificateOfComplianceWithDriverLicenseRequirements/certification';

export const certificationApi = createApi({
    reducerPath: 'certification',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/certification`
    }),
    tagTypes: ['Certification'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createCertification: builder.mutation<ICertification, ICertificationDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'Certification', id: 'Certification' }]
        }),
        updateCertification: builder.mutation<ICertification, ICertification>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: `PUT`,
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'Certification', id: 'Certification' }]
        }),
        deleteCertification: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'Certification', id: 'Certification' }]
        }),
        getCertification: builder.mutation<ICertification, number | void>({
            query: (id) => ({
                url: `/get-certification/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        }),
        getCertificationByUser: builder.query<ICertification, void>({
            query: () => ({
                url: `/get-certification-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'Certification', id: 'Certification' }]
        }),
        getCertificationByUserId: builder.query<ICertification, number | void>({
            query: (id) => ({
                url: `/get-certification-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'Certification', id: 'Certification' }]
        })
    })
});

export const {
    useCreateCertificationMutation,
    useUpdateCertificationMutation,
    useGetCertificationMutation,
    useGetCertificationByUserQuery,
    useGetCertificationByUserIdQuery,
    useDeleteCertificationMutation
} = certificationApi;
