import { Button, Dialog, DialogContent, DialogTitle, Grid, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect } from 'react';
import { INotesAndLinks, NotesAndLinksType } from '../../../../store/interfaces/PreventableMaintenance/notes.and.links';
import { useDeleteNotesAndLinksMutation } from '../../../../store/reducers/PreventableMaintenance/notes.links';
import { toast } from 'react-toastify';

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    note?: INotesAndLinks;
    setNote: (note?: INotesAndLinks) => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteNoteModal({ open, setOpen, note, setNote }: Readonly<IProps>) {
    const [deleteNote, { isSuccess, isError, reset }] = useDeleteNotesAndLinksMutation();

    useEffect(() => {
        if (isSuccess && open) {
            toast.success('Note deleted successfully');
            reset();
            setOpen(false);
            setNote(undefined);
        }
        if (isError && open) {
            toast.error('Failed to delete note');
            reset();
            setOpen(false);
            setNote(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, open, isError]);
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
                setOpen(false);
                setNote(undefined);
            }}
        >
            <DialogTitle sx={{ textAlign: 'center' }}>
                Delete {note?.type === NotesAndLinksType.LINK ? 'Link' : 'Note'} (
                {note?.type === NotesAndLinksType.LINK ? note?.link : note?.note_subject})
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center" spacing={1}>
                    <Grid item sm={12}>
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                            Are you sure you want to delete this{' '}
                            {note?.type === NotesAndLinksType.LINK ? 'link' : 'note'}?
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                if (note && note.id) {
                                    deleteNote(note.id);
                                }
                            }}
                            fullWidth
                            size="small"
                        >
                            Delete
                        </Button>
                    </Grid>
                    <Grid item sm={12}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setOpen(false);
                                setNote(undefined);
                            }}
                            fullWidth
                            size="small"
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
