/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import {
    IMember,
    IMemberRequest,
    IMemberRequestInput,
    IPostAccidentRequest,
    IRequest,
    IRequestStatus
} from '../../../../models';
import { KTSVG } from '../../../helpers';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    createMemberRequest,
    getMemberRequestByMember,
    getRequestStatuses,
    getRequestTypes
} from '../../../../shared/client/memberRequest';
import PostAccidentRequest from './PostAccidentRequest';
import { StripeProduct } from '../../../../app/modules/models';
import { createCheckoutSessionMemberRequest, getMemberRequestProducts } from '../../../../shared';
import { Grid, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider';

const testReqiuredMessage =
    'Based on the accident information you’ve provided, the FMCSA requires that the driver immediately schedule a post-accident drug and alcohol test. To do this please call us as soon as possible at (478) 221-7900 and click Submit';
const testNotRequiredMessage =
    'Based on the accident information you’ve provided, the FMCSA does not require the driver to be tested. It is highly recommended to not order a post-accident test if the above criteria is not met. However, if you would still would like to order a post-accident test, click below.';

const getPrice = (type: number, types: IRequest[], subscriptions: Array<StripeProduct>) => {
    const item = types?.find((item) => item.id === Number(type));
    const subscription = subscriptions?.find((x) => x.name.includes(item?.name!));
    return subscription?.unit_amount! / 100;
};
interface IProps {
    member: IMember;
    memberSubscription?: StripeProduct;
}

const initialValues: IMemberRequestInput = {
    member: 0,
    type: 0,
    requires_payment: false,
    city: '',
    state: '',
    zip_code: ''
};

const postAccidentInitialValues: IPostAccidentRequest = {
    fatality: '',
    ticketed: '',
    towAway: '',
    injured: ''
};

const postAccidentRequestSchema = Yup.object().shape({
    fatality: Yup.string().equals(['Yes', 'No', '']).required('Fatality is required'),
    ticketed: Yup.string().equals(['Yes', 'No', '']).required('Ticketed is required'),
    towAway: Yup.string().equals(['Yes', 'No', '']).required('Tow Away is required'),
    injured: Yup.string().equals(['Yes', 'No', '']).required('Injured is required')
});

const memberRequestSchema = Yup.object().shape({
    member: Yup.number().min(1).required('Member is required'),
    type: Yup.number().min(1).required('Request Type is required'),
    // If type is 8 then city and state are required
    city: Yup.string().when('type', {
        is: (val: number) => val === 8,
        then: Yup.string().required('City is required')
    }),
    state: Yup.string().when('type', {
        is: (val: number) => val === 8,
        then: Yup.string().required('State is required')
    })
});

const drugTests = [
    'Return-to-Duty Drug and Alcohol Test',
    'Post-Accident Drug and Alcohol Test',
    'Reasonable Suspicion Drug and Alcohol Test',
    'Follow-Up Drug and Alcohol Test'
];

const RequestModal = ({ member, memberSubscription }: IProps) => {
    const { mode } = useThemeMode();
    const queryClient = useQueryClient();
    const [message, setMessage] = React.useState('');
    const [postAccidentDone, setPostAccidentDone] = React.useState(false);
    const [requestTypes, setRequestTypes] = React.useState<IRequest[]>([]);
    const [subscriptions, setSubscriptions] = React.useState<Array<StripeProduct>>([]);
    const [selectedPlan, setSelectedPlan] = React.useState<StripeProduct>();
    const [phoneNumbers, setPhoneNumbers] = React.useState<{
        driver_phone_number: string;
        owner_phone_number: string;
    }>({
        driver_phone_number: '',
        owner_phone_number: ''
    });
    const { data: statuses } = useQuery<IRequestStatus[]>('requestStatuses', async () => getRequestStatuses(), {
        refetchOnWindowFocus: false
    });
    const { data: types = [] } = useQuery<IRequest[]>('requestTypes', async () => getRequestTypes(), {
        refetchOnWindowFocus: false
    });

    const { data: requests = [] } = useQuery<IMemberRequest[]>(
        'memberRequests',
        async () => getMemberRequestByMember(Number(member?.id)),
        {
            refetchOnWindowFocus: false
        }
    );

    const checkout = async (id: number) => {
        if (selectedPlan) {
            const url = await createCheckoutSessionMemberRequest(
                selectedPlan.price_id,
                selectedPlan.type,
                selectedPlan.tiers_mode,
                id
            );
            window.open(url.url, '_self');
        }
    };

    const getPlanOptions = React.useCallback(async () => {
        try {
            if (memberSubscription) {
                const res = await getMemberRequestProducts(memberSubscription?.name);
                const lookupKeyItems = res.data.filter((item: any) => item.lookup_key);
                const itemsWithOutLookupKey: any[] = [];
                for (const item of res.data as any) {
                    if (!item.lookup_key) {
                        const exists = lookupKeyItems.find((lookup: any) => lookup.name === item.name);
                        if (!exists) {
                            itemsWithOutLookupKey.push(item);
                        }
                    }
                }
                const itemsList = [...lookupKeyItems, ...itemsWithOutLookupKey];
                setSubscriptions(itemsList);
            }
        } catch (error) {
            console.log(error);
        }
    }, [memberSubscription]);

    const memberRequestMutation = useMutation(createMemberRequest, {
        onSuccess: (data: IMemberRequest) => {
            if (
                formik.values.requires_payment &&
                types.find((item) => item.id === Number(formik.values.type))?.name.toLowerCase() !==
                    'Random Drug and Alcohol Test'.toLowerCase()
            ) {
                checkout(data.id).then(() => {
                    queryClient.invalidateQueries('memberRequests');
                    formik.resetForm();
                    postAccidentFormik.resetForm();
                    setMessage('');
                    setPostAccidentDone(false);
                });
            }
            formik.resetForm();
            postAccidentFormik.resetForm();
            setMessage('');
            setPostAccidentDone(false);
            if (formik.values.type === 7) {
                toast.success(
                    `A request for ${member?.user?.first_name} ${member?.user?.last_name}’s random test has been made. You can now inform the driver that they’ve been selected. Immediately after you’ve informed the driver of their selection, return to their profile and click the green button that says Confirm Random Test Request. The test will immediately be scheduled and the driver will be texted the testing location information based on the driver’s current location you provide when confirming the request`
                );
            } else {
                toast.success('Request Created Successfully');
            }
        }
    });

    const [loading, setLoading] = React.useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema: memberRequestSchema,
        onSubmit: async (values) => {
            setLoading(true);
            // Check if request exists for user
            const exists = requests?.find(
                (request) => request.type.id === values.type && request.status !== 'Completed'
            );
            if (exists) {
                setLoading(false);
                return toast.error('Request already exists');
            }
            if (
                types?.find((item) => item.id === Number(formik.values.type))?.name ===
                'Post-Accident Drug and Alcohol Test'
            ) {
                if (message === testNotRequiredMessage) {
                    const confirm = window.confirm(
                        'Given your answers, the FMCSA does not require the driver to be tested and it is highly recommended to not order a post-accident test if the above criteria is not met. However, if you still would like to order a post-accident test click OK.'
                    );
                    if (!confirm) {
                        setLoading(false);
                        return;
                    }
                    if (phoneNumbers.driver_phone_number === '' || phoneNumbers.owner_phone_number === '') {
                        return toast.error('Please enter Both Company and Driver Phone Numbers');
                    }
                    memberRequestMutation.mutate({
                        ...values,
                        ...postAccidentFormik.values,
                        driver_phone_number: phoneNumbers.driver_phone_number,
                        owner_phone_number: phoneNumbers.owner_phone_number
                    });
                } else {
                    if (phoneNumbers.driver_phone_number === '' || phoneNumbers.owner_phone_number === '') {
                        return toast.error('Please enter Both Company and Driver Phone Numbers');
                    }
                    memberRequestMutation.mutate({
                        ...values,
                        ...postAccidentFormik.values,
                        driver_phone_number: phoneNumbers.driver_phone_number,
                        owner_phone_number: phoneNumbers.owner_phone_number
                    });
                }
            } else {
                memberRequestMutation.mutate(values);
            }
            setLoading(false);
        }
    });

    const postAccidentFormik = useFormik({
        initialValues: postAccidentInitialValues,
        validationSchema: postAccidentRequestSchema,
        onSubmit: async (values) => {}
    });

    React.useEffect(() => {
        if (types.length > 0 && memberSubscription) {
            if (
                memberSubscription.name.toLowerCase() === 'Master Package'.toLowerCase() ||
                memberSubscription.name.toLowerCase() === 'Deluxe Package'.toLowerCase() ||
                memberSubscription?.name?.toLowerCase() === 'Deluxe Mini Package'.toLowerCase()
            ) {
                setRequestTypes(types);
            }
            if (memberSubscription.name.toLowerCase() === 'Driver File Package'.toLowerCase()) {
                setRequestTypes(
                    types.filter((type) => type.name === 'Motor Vehicle Record' || type.name === 'Background Check')
                );
            }
            if (memberSubscription.name.toLowerCase() === 'Drug and Alcohol Package'.toLowerCase()) {
                setRequestTypes(
                    types.filter(
                        (type) =>
                            type.name === 'DACH Query' ||
                            type.name === 'Post-Accident Drug and Alcohol Test' ||
                            type.name === 'Return-to-Duty Drug and Alcohol Test' ||
                            type.name === 'Reasonable Suspicion Drug and Alcohol Test' ||
                            type.name === 'Follow-Up Drug and Alcohol Test' ||
                            type.name === 'Random Drug and Alcohol Test'
                    )
                );
            }
        }
    }, [types, memberSubscription]);

    React.useEffect(() => {
        setPostAccidentDone(false);
        setMessage('');
        if (postAccidentFormik.values.fatality === 'Yes') {
            setMessage(testReqiuredMessage);
            setPostAccidentDone(true);
        }

        if (postAccidentFormik.values.fatality === 'No' && postAccidentFormik.values.ticketed === 'No') {
            setMessage(testNotRequiredMessage);
            setPostAccidentDone(true);
        }

        if (
            postAccidentFormik.values.fatality === 'No' &&
            postAccidentFormik.values.ticketed === 'Yes' &&
            postAccidentFormik.values.towAway === 'Yes'
        ) {
            setMessage(testReqiuredMessage);
            setPostAccidentDone(true);
        }
        if (
            postAccidentFormik.values.fatality === 'No' &&
            postAccidentFormik.values.ticketed === 'Yes' &&
            postAccidentFormik.values.towAway === 'No' &&
            postAccidentFormik.values.injured === 'Yes'
        ) {
            setMessage(testReqiuredMessage);
            setPostAccidentDone(true);
        }
        if (
            postAccidentFormik.values.fatality === 'No' &&
            postAccidentFormik.values.ticketed === 'Yes' &&
            postAccidentFormik.values.towAway === 'No' &&
            postAccidentFormik.values.injured === 'No'
        ) {
            setMessage(testNotRequiredMessage);
            setPostAccidentDone(true);
        }
    }, [postAccidentFormik.values]);

    React.useEffect(() => {
        if (postAccidentFormik.values.fatality === 'No') {
            postAccidentFormik.setFieldValue('ticketed', '');
            postAccidentFormik.setFieldValue('towAway', '');
            postAccidentFormik.setFieldValue('injured', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postAccidentFormik.values.fatality]);

    React.useEffect(() => {
        if (postAccidentFormik.values.ticketed === 'No') {
            postAccidentFormik.setFieldValue('towAway', '');
            postAccidentFormik.setFieldValue('injured', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postAccidentFormik.values.ticketed]);
    React.useEffect(() => {
        if (postAccidentFormik.values.towAway === 'No') {
            postAccidentFormik.setFieldValue('injured', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postAccidentFormik.values.towAway]);

    React.useEffect(() => {
        getPlanOptions();
    }, [getPlanOptions]);

    React.useEffect(() => {
        if (member) {
            formik.setFieldValue('member', member.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="modal fade" id="request_modal" role="dialog" tabIndex={-1} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                    <div className="modal-header">
                        {/* begin::modal title */}
                        <h2 className="fw-bolder">Make Request</h2>
                        {/* end::modal title */}

                        {/* begin::Close */}
                        <div
                            className="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                formik.resetForm();
                                postAccidentFormik.resetForm();
                                setMessage('');
                                setPostAccidentDone(false);
                            }}
                            aria-label="Close"
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                        </div>
                        {/* end::Close */}
                    </div>
                    <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                        <form onSubmit={formik.handleSubmit} className="form" id="kt_modal_request_form">
                            <div className="fv-row mb-7">
                                <label className="form-label fw-bolder text-dark fs-6">Request Type</label>
                                <select
                                    className={clsx('form-select form-select-solid', {
                                        'is-invalid': formik.touched.type && formik.errors.type
                                    })}
                                    value={formik.values.type}
                                    onChange={(e) => {
                                        const item = types?.find((item) => item.id === Number(e.target.value));
                                        if (item) {
                                            formik.setFieldValue('type', item.id);
                                            if (
                                                memberSubscription?.name.toLowerCase() !==
                                                'Deluxe Mini Package'.toLowerCase()
                                            ) {
                                                formik.setFieldValue('requires_payment', true);
                                            } else {
                                                formik.setFieldValue('requires_payment', item.requires_payment);
                                            }
                                            setSelectedPlan(
                                                subscriptions?.find(
                                                    (item) =>
                                                        item.name ===
                                                        types?.find((item) => item.id === Number(e.target.value))?.name
                                                )
                                            );
                                        }
                                    }}
                                >
                                    <option value={0}>Select</option>
                                    {requestTypes
                                        ?.filter(
                                            (item) =>
                                                item.name.toLowerCase() !== 'Random Drug and Alcohol Test'.toLowerCase()
                                        )
                                        ?.map((type) => (
                                            <option key={type.id} value={type.id}>
                                                {type.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            {memberSubscription?.name.toLowerCase() !== 'Deluxe Mini Package'.toLowerCase() &&
                                formik.values.requires_payment && (
                                    <div className="fv-row mb-7">
                                        <p
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 3,
                                                textAlign: 'center',
                                                color: 'red',
                                                fontSize: 13
                                            }}
                                        >
                                            {['Motor Vehicle Record', 'DACH Query'].includes(
                                                types?.find((item) => item.id === Number(formik.values.type))?.name!
                                            )
                                                ? `An Annual ${
                                                      types?.find((item) => item.id === Number(formik.values.type))
                                                          ?.name
                                                  } is already included in your subscription. Would you still like to order one at an additional cost of $${getPrice(
                                                      formik.values.type,
                                                      types,
                                                      subscriptions
                                                  )}?`
                                                : `A ${
                                                      types?.find((item) => item.id === Number(formik.values.type))
                                                          ?.name
                                                  } is not included in your subscription. Would you still like to order one at an additional cost of $${getPrice(
                                                      formik.values.type,
                                                      types,
                                                      subscriptions
                                                  )}?`}
                                        </p>
                                    </div>
                                )}
                            {memberSubscription?.name.toLowerCase() === 'Deluxe Mini Package'.toLowerCase() &&
                                formik.values.requires_payment && (
                                    <div className="fv-row mb-7">
                                        <p
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 3,
                                                textAlign: 'center',
                                                color: 'red',
                                                fontSize: 13
                                            }}
                                        >
                                            {[
                                                'Motor Vehicle Record',
                                                'DACH Query',
                                                'Background Check',
                                                'Post-Accident Drug and Alcohol Test',
                                                'Return-to-Duty Drug and Alcohol Test',
                                                'Reasonable Suspicion Drug and Alcohol Test',
                                                'Random Drug and Alcohol Test',
                                                'Follow-Up Drug and Alcohol Test'
                                            ].includes(
                                                types?.find((item) => item.id === Number(formik.values.type))?.name!
                                            )
                                                ? `A ${
                                                      types?.find((item) => item.id === Number(formik.values.type))
                                                          ?.name
                                                  } is an additional cost of $${getPrice(
                                                      formik.values.type,
                                                      types,
                                                      subscriptions
                                                  )}?`
                                                : `A ${
                                                      types?.find((item) => item.id === Number(formik.values.type))
                                                          ?.name
                                                  } is not included in your subscription. Would you still like to order one at an additional cost of $${getPrice(
                                                      formik.values.type,
                                                      types,
                                                      subscriptions
                                                  )}?`}
                                        </p>
                                    </div>
                                )}
                            {drugTests.includes(types.find((item) => item.id === Number(formik.values.type))?.name!) ? (
                                <Grid container spacing={3}>
                                    <Grid item sm={12}>
                                        <Typography variant="h6" style={{ textAlign: 'center', fontSize: 13 }}>
                                            Drivers Current Location
                                        </Typography>
                                        <label
                                            className="fw-bolder text-dark fs-6"
                                            style={{
                                                marginTop: 10,
                                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                            }}
                                        >
                                            City
                                        </label>
                                        <TextField
                                            fullWidth
                                            placeholder="City"
                                            variant="outlined"
                                            value={formik.values.city}
                                            onChange={(e) => formik.setFieldValue('city', e.target.value)}
                                            style={{ marginBottom: 10 }}
                                            size="small"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    },
                                                    '&:hover fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    }
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <label
                                            className="fw-bolder text-dark fs-6"
                                            style={{
                                                marginTop: 10,
                                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                            }}
                                        >
                                            State
                                        </label>
                                        <TextField
                                            fullWidth
                                            placeholder="State"
                                            variant="outlined"
                                            value={formik.values.state}
                                            onChange={(e) => formik.setFieldValue('state', e.target.value)}
                                            style={{ marginBottom: 10 }}
                                            size="small"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    },
                                                    '&:hover fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    }
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <label
                                            className="fw-bolder text-dark fs-6"
                                            style={{
                                                marginTop: 10,
                                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                            }}
                                        >
                                            Zip Code
                                        </label>
                                        <TextField
                                            fullWidth
                                            placeholder="Zip Code"
                                            variant="outlined"
                                            value={formik.values.zip_code}
                                            onChange={(e) => formik.setFieldValue('zip_code', e.target.value)}
                                            style={{ marginBottom: 10 }}
                                            size="small"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    },
                                                    '&:hover fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    }
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}
                            {types?.find((item) => item.id === Number(formik.values.type))?.name ===
                            'Post-Accident Drug and Alcohol Test' ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <label
                                            className="fw-bolder text-dark fs-6"
                                            style={{
                                                marginTop: 10,
                                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                            }}
                                        >
                                            Driver Phone Number
                                        </label>
                                        <TextField
                                            fullWidth
                                            placeholder="Driver Phone Number"
                                            variant="outlined"
                                            value={phoneNumbers.driver_phone_number}
                                            onChange={(e) =>
                                                setPhoneNumbers({
                                                    ...phoneNumbers,
                                                    driver_phone_number: e.target.value
                                                })
                                            }
                                            style={{ marginBottom: 10 }}
                                            size="small"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    },
                                                    '&:hover fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    }
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <label
                                            className="fw-bolder text-dark fs-6"
                                            style={{
                                                marginTop: 10,
                                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                            }}
                                        >
                                            Owner Phone Number
                                        </label>
                                        <TextField
                                            fullWidth
                                            placeholder="Owner Phone Number"
                                            variant="outlined"
                                            value={phoneNumbers.owner_phone_number}
                                            onChange={(e) =>
                                                setPhoneNumbers({ ...phoneNumbers, owner_phone_number: e.target.value })
                                            }
                                            style={{ marginBottom: 10 }}
                                            size="small"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    },
                                                    '&:hover fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        // Check Validation
                                                        borderColor:
                                                            mode === 'dark'
                                                                ? 'rgba(255,255,255,255)'
                                                                : 'rgba(30,30,45,255)'
                                                    }
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                            InputProps={{
                                                style: {
                                                    color: 'gray'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : null}
                            {types?.find((item) => item.id === Number(formik.values.type))?.name ===
                            'Post-Accident Drug and Alcohol Test' ? (
                                <PostAccidentRequest postAccidentFormik={postAccidentFormik} />
                            ) : null}
                            {message &&
                                types?.find((item) => item.id === Number(formik.values.type))?.name ===
                                    'Post-Accident Drug and Alcohol Test' && (
                                    <h4
                                        style={{
                                            color: 'red',
                                            textAlign: 'center',
                                            marginTop: 10,
                                            marginBottom: 10
                                        }}
                                    >
                                        {message}
                                    </h4>
                                )}
                            {types?.find((item) => item.id === Number(formik.values.type))?.name ===
                                'Random Drug and Alcohol Test' && (
                                <h5 className="text-center text-danger">
                                    By making this request you are confirming that you’ve viewed a random selection for
                                    this driver and will proceed to inform the driver of their selection
                                </h5>
                            )}
                            <div className="text-center">
                                <button
                                    type="submit"
                                    id="kt_modal_request_submit"
                                    className="btn btn-primary"
                                    disabled={loading}
                                    data-bs-dismiss="modal"
                                    onClick={(e) => {}}
                                >
                                    <span className="indicator-label">Submit</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestModal;
