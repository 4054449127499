import { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import { useAuth } from '../app/modules/auth';
import { ICompany, IMember } from '../models';
import { getCompanies, getCompanyMembers, getCurrentCompany } from '../shared';
import { PlaidItem } from '../app/modules/models';

type ContextProps = {
    currentCompany: ICompany | undefined;
    currentCompanyMembers: IMember[] | undefined;
    paymentInfo: PlaidItem[] | undefined;
    setPaymentInfo: (x: PlaidItem[]) => void;
    getCurrentCompanyById: (id: number) => void;
};

const initContextPropsState = {
    currentCompany: undefined,
    currentCompanyMembers: undefined,
    paymentInfo: undefined,
    setPaymentInfo: () => {},
    getCurrentCompanyById: () => {}
};

const CompanyContext = createContext<ContextProps>(initContextPropsState);

const useCompany = () => {
    return useContext(CompanyContext);
};

function CompanyProvider({ children }: { children: ReactNode }) {
    const { currentUser } = useAuth();
    const [currentCompany, setCurrentCompany] = useState<ICompany | undefined>(undefined);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [currentCompanyMembers, setMembers] = useState<IMember[]>([]);
    const [paymentInfo, setPaymentInfo] = useState<PlaidItem[]>();
    const getCurrentCompanyById = async (id: number) => {
        getCompanies().then((companies: ICompany[]) => {
            setCurrentCompany(companies.find((company) => Number(company.id) === id));
        });
    };

    useEffect(() => {
        if (currentUser && currentUser.role === 'Owner' && currentUser?.company) {
            getCurrentCompany().then((company) => {
                setCurrentCompany(company);
            });
        }
        if (currentUser && currentUser.role === 'Member' && currentUser?.member?.company) {
            getCompanies().then((companies: ICompany[]) => {
                setCurrentCompany(
                    companies.find(
                        (company) =>
                            Number(company.id) === Number(currentUser.member?.company) ||
                            Number(company.id) === Number(currentUser?.maintenance_member?.company)
                    )
                );
            });
        }
        if (currentUser && currentUser.role === 'MaintenanceMember' && currentUser?.maintenance_member?.company) {
            getCompanies().then((companies: ICompany[]) => {
                setCurrentCompany(
                    companies.find((company) => Number(company.id) === Number(currentUser?.maintenance_member?.company))
                );
            });
        }
        if (currentUser && ['Admin', 'SuperAdmin'].includes(currentUser.role)) {
            getCompanies().then((companies: ICompany[]) => {
                setCurrentCompany(companies[0]);
            });
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentCompany) {
            getCompanyMembers(currentCompany.id).then((members) => {
                setMembers(members);
            });
        }
    }, [currentCompany]);

    return (
        <CompanyContext.Provider
            value={{ currentCompany, currentCompanyMembers, paymentInfo, setPaymentInfo, getCurrentCompanyById }}
        >
            {children}
        </CompanyContext.Provider>
    );
}

export { CompanyProvider, useCompany };
