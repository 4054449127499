import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IMaintenanceMember } from '../../interfaces/PreventableMaintenance/maintenance.members';

export const maintenanceMemberApi = createApi({
    reducerPath: 'pm_maintenance_member',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/auth/api/preventable-maintenance/maintenance-members`
    }),
    tagTypes: ['MaintenanceMember'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createMaintenanceMember: builder.mutation<IMaintenanceMember, Partial<IMaintenanceMember>>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body: {
                    member: body?.member?.id,
                    first_name: body?.first_name,
                    last_name: body?.last_name,
                    email: body?.email,
                    company: body?.company?.id,
                    phone_number: body?.phone_number
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'MaintenanceMember', id: 'MaintenanceMember' }]
        }),
        updateMaintenanceMember: builder.mutation<IMaintenanceMember, IMaintenanceMember>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'MaintenanceMember', id: 'MaintenanceMember' }]
        }),
        deleteMaintenanceMember: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'MaintenanceMember', id: 'MaintenanceMember' }]
        }),
        getMaintenanceMember: builder.query<IMaintenanceMember, number | void>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'MaintenanceMember', id: 'MaintenanceMember' }]
        }),
        getMaintenanceMembers: builder.query<IMaintenanceMember[], void>({
            query: () => ({
                url: '/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'MaintenanceMember', id: 'MaintenanceMember' }]
        }),
        getMaintenanceMembersByCompany: builder.query<IMaintenanceMember[], number | void>({
            query: (companyId) => ({
                url: `/get-by-company?company_id=${companyId}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'MaintenanceMember', id: 'MaintenanceMember' }]
        })
    })
});

export const {
    useCreateMaintenanceMemberMutation,
    useUpdateMaintenanceMemberMutation,
    useDeleteMaintenanceMemberMutation,
    useGetMaintenanceMemberQuery,
    useGetMaintenanceMembersQuery,
    useGetMaintenanceMembersByCompanyQuery
} = maintenanceMemberApi;
