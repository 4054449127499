import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IDutyHoursDriverSignature,
    IDutyHoursDriverSignatureDto
} from '../../interfaces/DriversStatementDutyHours/drvivers-signature';

export const driverSignatureApi = createApi({
    reducerPath: 'driver_signature',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/driver-signature`
    }),
    tagTypes: ['DriverSignature'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createDriverSignature: builder.mutation<IDutyHoursDriverSignature, IDutyHoursDriverSignatureDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriverSignature', id: 'DriverSignature' }]
        }),
        updateDriverSignature: builder.mutation<IDutyHoursDriverSignature, IDutyHoursDriverSignature>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriverSignature', id: 'DriverSignature' }]
        }),
        deleteDriverSignature: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'DriverSignature', id: 'DriverSignature' }]
        }),
        getDriverSignature: builder.query<IDutyHoursDriverSignature, number | void>({
            query: (id) => ({
                url: `/get-driver-signature/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverSignature', id: 'DriverSignature' }]
        }),
        getDriverSignatureByUser: builder.query<IDutyHoursDriverSignature, void>({
            query: () => ({
                url: `/get-driver-signature-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverSignature', id: 'DriverSignature' }]
        }),
        getDriverSignatureByUserId: builder.query<IDutyHoursDriverSignature, number | void>({
            query: (id) => ({
                url: `/get-driver-signature-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'DriverSignature', id: 'DriverSignature' }]
        })
    })
});

export const {
    useCreateDriverSignatureMutation,
    useUpdateDriverSignatureMutation,
    useGetDriverSignatureQuery,
    useGetDriverSignatureByUserQuery,
    useGetDriverSignatureByUserIdQuery,
    useDeleteDriverSignatureMutation
} = driverSignatureApi;
