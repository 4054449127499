import React from 'react';
import { IMember } from '../../../models';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { IRequestForCheckLetterDto } from '../../../store/interfaces/RequestForCheckOfDrivingRecords/request-for-check-letter';
import {
    useCreateRequestForCheckLetterMutation,
    useUpdateRequestForCheckLetterMutation,
    useGetRequestForCheckLetterByUserIdQuery,
    useDeleteRequestForCheckLetterMutation
} from '../../../store/reducers/RequestForCheckOfDrivingRecords/request-for-check-letter';
import { useAuth } from '../../../app/modules/auth';
import { useThemeMode } from '../../partials';
import { useGetAddressQuery } from '../../../store/reducers/DriversApplicationForEmployment/applicant-information';
import { changeStringLast4 } from '../../../shared';

const initialValues: Partial<IRequestForCheckLetterDto> = {
    applicant_name: '',
    position: '',
    driver_name: '',
    address: '',
    former_address: '',
    date_of_birth: '',
    social_security_number: '',
    driver_license_number: ''
};

const validationSchema = Yup.object({
    applicant_name: Yup.string().required('Required'),
    position: Yup.string().required('Required'),
    driver_name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    former_address: Yup.string().required('Required'),
    date_of_birth: Yup.string().required('Required'),
    social_security_number: Yup.string().required('Required'),
    driver_license_number: Yup.string().required('Required')
});

const RequestForCheckLetter = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetRequestForCheckLetterByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const { data: addresses = [] } = useGetAddressQuery(Number(member?.user?.id), {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [createRequestForCheckLetter, { isLoading: isCreating }] = useCreateRequestForCheckLetterMutation();
    const [updateRequestForCheckLetter, { isLoading: isUpdating }] = useUpdateRequestForCheckLetterMutation();
    const [deleteRequestForCheckLetter] = useDeleteRequestForCheckLetterMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                await updateRequestForCheckLetter({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    id: data.id
                });
            } else {
                await createRequestForCheckLetter({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
            }
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IRequestForCheckLetterDto> = {};

            if (!values.applicant_name) {
                errors.applicant_name = 'Required';
            }

            if (!values.position) {
                errors.position = 'Required';
            }

            if (!values.driver_name) {
                errors.driver_name = 'Required';
            }

            if (!values.address) {
                errors.address = 'Required';
            }

            if (!values.former_address) {
                errors.former_address = 'Required';
            }

            if (!values.date_of_birth) {
                errors.date_of_birth = 'Required';
            }

            if (!values.social_security_number) {
                errors.social_security_number = 'Required';
            }

            if (!values.driver_license_number) {
                errors.driver_license_number = 'Required';
            }

            return errors;
        }
    });

    React.useEffect(() => {
        if (member) {
            const utcDate = new Date(member?.date_of_birth);

            const offsetMinutes = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

            // Format the date to "YYYY-MM-DD"
            const adjustedYear = utcDate.getFullYear();
            const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
            const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${adjustedYear}-${adjustedMonth}-${adjustedDay}`;
            if (data) {
                formik.setValues({
                    ...data,
                    driver_license_number: ['Member', 'Owner'].includes(currentUser?.role!)
                        ? changeStringLast4(data?.driver_license_number, '*')!
                        : data?.driver_license_number,
                    social_security_number: ['Member', 'Owner'].includes(currentUser?.role!)
                        ? changeStringLast4(data?.social_security_number, '*')!
                        : data?.social_security_number
                });
            } else if (member) {
                const currentAddress = addresses?.find((address) => address?.is_current);
                formik.setValues({
                    ...initialValues,
                    driver_name: `${member?.user?.first_name} ${member?.user?.last_name}`,
                    applicant_name: `${member?.user?.first_name} ${member?.user?.last_name}`,
                    date_of_birth: formattedDate,
                    social_security_number: member?.social_security_number,
                    driver_license_number: member?.drivers_license_number,
                    address: currentAddress
                        ? `${currentAddress?.city}, ${currentAddress?.state}, ${currentAddress?.zip_code} `
                        : ''
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, addresses]);

    React.useEffect(() => {
        if (addresses.length > 0) {
            const currentAddress = addresses?.find((address) => address?.is_current);
            const mostRecentAddress = addresses.reduce((mostRecent, current) => {
                const mostRecentDate = new Date(mostRecent?.created_at!);
                const currentDate = new Date(current.created_at!);

                return mostRecentDate > currentDate ? mostRecent : current;
            }, addresses[0]);

            if (currentAddress) {
                formik.setFieldValue(
                    'address',
                    `${currentAddress?.street}, ${currentAddress?.city}, ${currentAddress?.state}, ${currentAddress?.zip_code}`
                );
            }

            if (mostRecentAddress) {
                formik.setFieldValue(
                    'former_address',
                    `${mostRecentAddress?.street}, ${mostRecentAddress?.city}, ${mostRecentAddress?.state}, ${mostRecentAddress?.zip_code}`
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addresses]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Request for Check Letter
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 5
                }}
                variant="h6"
                gutterBottom
                component="h6"
            >
                TO: Compliance Masters LLC. 1505 Monument Hill Rd. Charlotte, NC 28213
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify',
                            mt: 5
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        Dear Sir / Madam:
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        Name of Applicant
                    </Typography>
                    <TextField
                        fullWidth
                        id="applicant_name"
                        placeholder="Name of Applicant"
                        size="small"
                        variant="outlined"
                        value={formik.values.applicant_name}
                        // onChange={(e) => {
                        //     formik.setFieldValue('applicant_name', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        has made application with our company for the position of:
                    </Typography>
                    <TextField
                        fullWidth
                        id="position"
                        placeholder="position"
                        size="small"
                        variant="outlined"
                        value={formik.values.position}
                        onChange={(e) => {
                            formik.setFieldValue('position', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.position && Boolean(formik.errors.position) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.position && Boolean(formik.errors.position) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.position && Boolean(formik.errors.position) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        In accordance with Section 391.23, Federal Department of Transportation Regulations, please
                        provide the signed requester with the applicant's driving record for the past three years.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        Name of Applicant
                    </Typography>
                    <TextField
                        fullWidth
                        id="applicant_name"
                        // placeholder='applicant_name'
                        size="small"
                        variant="outlined"
                        value={formik.values.applicant_name}
                        onChange={(e) => {
                            formik.setFieldValue('applicant_name', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        is employed with our company in the position of:
                    </Typography>
                    <TextField
                        fullWidth
                        id="position"
                        placeholder="position"
                        size="small"
                        variant="outlined"
                        value={formik.values.position}
                        onChange={(e) => {
                            formik.setFieldValue('position', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.position && Boolean(formik.errors.position) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.position && Boolean(formik.errors.position) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.position && Boolean(formik.errors.position) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        In accordance with Section 391.25, Federal Department of Transportation Regulations, please
                        provide the signed requester with the employee's driving record for the past year.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        Name of Driver
                    </Typography>
                    <TextField
                        fullWidth
                        id="driver_name"
                        placeholder="Driver Name"
                        size="small"
                        variant="outlined"
                        value={formik.values.driver_name}
                        onChange={(e) => {
                            formik.setFieldValue('driver_name', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_name && Boolean(formik.errors.driver_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_name && Boolean(formik.errors.driver_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_name && Boolean(formik.errors.driver_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        Address
                    </Typography>
                    <TextField
                        fullWidth
                        id="address"
                        placeholder="Address"
                        size="small"
                        variant="outlined"
                        value={formik.values.address}
                        onChange={(e) => {
                            formik.setFieldValue('address', e.target.value);
                        }}
                        helperText={'(Street, City, State, Zip Code)'}
                        FormHelperTextProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address && Boolean(formik.errors.address) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address && Boolean(formik.errors.address) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address && Boolean(formik.errors.address) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        Former Address
                    </Typography>
                    <TextField
                        fullWidth
                        id="former_address"
                        placeholder="Former Address"
                        size="small"
                        variant="outlined"
                        value={formik.values.former_address}
                        onChange={(e) => {
                            formik.setFieldValue('former_address', e.target.value);
                        }}
                        FormHelperTextProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.former_address && Boolean(formik.errors.former_address)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.former_address && Boolean(formik.errors.former_address)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.former_address && Boolean(formik.errors.former_address)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        Date of Birth
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_of_birth"
                        placeholder="Date of Birth"
                        size="small"
                        variant="outlined"
                        value={formik.values.date_of_birth}
                        type="date"
                        onChange={(e) => {
                            formik.setFieldValue('date_of_birth', e.target.value);
                        }}
                        FormHelperTextProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        SSN
                    </Typography>
                    <TextField
                        fullWidth
                        id="social_security_number"
                        placeholder="SSN"
                        size="small"
                        variant="outlined"
                        value={formik.values.social_security_number}
                        onChange={(e) => {
                            formik.setFieldValue('social_security_number', e.target.value);
                        }}
                        FormHelperTextProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.social_security_number &&
                                        Boolean(formik.errors.social_security_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.social_security_number &&
                                        Boolean(formik.errors.social_security_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.social_security_number &&
                                        Boolean(formik.errors.social_security_number)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        Drivers License Number
                    </Typography>
                    <TextField
                        fullWidth
                        id="driver_license_number"
                        placeholder="Drivers License Number"
                        size="small"
                        variant="outlined"
                        value={formik.values.driver_license_number}
                        onChange={(e) => {
                            formik.setFieldValue('driver_license_number', e.target.value);
                        }}
                        FormHelperTextProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_license_number &&
                                        Boolean(formik.errors.driver_license_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_license_number &&
                                        Boolean(formik.errors.driver_license_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_license_number &&
                                        Boolean(formik.errors.driver_license_number)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deleteRequestForCheckLetter(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default RequestForCheckLetter;
