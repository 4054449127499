import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IApplicantQuestions, IApplicantQuestionsDto } from '../../interfaces/EmploymentHistory/applicant-questions';

export const applicantQuestionsApi = createApi({
    reducerPath: 'applicant_questions',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/applicant-questions`
    }),
    tagTypes: ['ApplicantQuestions'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createApplicantQuestions: builder.mutation<IApplicantQuestions, IApplicantQuestionsDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ApplicantQuestions', id: 'ApplicantQuestions' }]
        }),
        updateApplicantQuestions: builder.mutation<IApplicantQuestions, IApplicantQuestions>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ApplicantQuestions', id: 'ApplicantQuestions' }]
        }),
        deleteApplicantQuestions: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'ApplicantQuestions', id: 'ApplicantQuestions' }]
        }),
        getApplicantQuestions: builder.query<IApplicantQuestions, number | void>({
            query: (id) => ({
                url: '/get-applicant-questions/',
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ApplicantQuestions', id: 'ApplicantQuestions' }]
        }),
        getApplicantQuestionsByUser: builder.query<IApplicantQuestions, void>({
            query: () => ({
                url: '/get-applicant-questions-by-user/',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ApplicantQuestions', id: 'ApplicantQuestions' }]
        }),
        getApplicantQuestionsByUserId: builder.query<IApplicantQuestions, number | void>({
            query: (id) => ({
                url: '/get-applicant-questions-by-user-id/',
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'ApplicantQuestions', id: 'ApplicantQuestions' }]
        })
    })
});

export const {
    useCreateApplicantQuestionsMutation,
    useUpdateApplicantQuestionsMutation,
    useGetApplicantQuestionsQuery,
    useGetApplicantQuestionsByUserQuery,
    useGetApplicantQuestionsByUserIdQuery,
    useDeleteApplicantQuestionsMutation
} = applicantQuestionsApi;
