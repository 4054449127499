import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    IAnnualReviewOfDrivingRecord,
    IAnnualReviewOfDrivingRecordDto,
    IReminder
} from '../../interfaces/AnnualReviewOfDrivingRecord/annual-review-of_driving-record';

interface IReminderInput {
    company_id?: string;
    member_id?: string;
}

export const annualReviewOfDrivingRecordApi = createApi({
    reducerPath: 'annual_review_of_driving_record',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/annual-review-of-driving-record`
    }),
    keepUnusedDataFor: 60 * 10, // 10 minutes
    tagTypes: ['AnnualReviewOfDrivingRecord'],
    endpoints: (builder) => ({
        createAnnualReviewOfDrivingRecord: builder.mutation<
            IAnnualReviewOfDrivingRecord,
            IAnnualReviewOfDrivingRecordDto
        >({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'AnnualReviewOfDrivingRecord', id: 'AnnualReviewOfDrivingRecord' }]
        }),
        updateAnnualReviewOfDrivingRecord: builder.mutation<IAnnualReviewOfDrivingRecord, IAnnualReviewOfDrivingRecord>(
            {
                query: (body) => ({
                    url: `/${body.id}/`,
                    method: `PUT`,
                    body,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                    }
                }),
                invalidatesTags: [{ type: 'AnnualReviewOfDrivingRecord', id: 'AnnualReviewOfDrivingRecord' }]
            }
        ),
        getAnnualReviewOfDrivingRecord: builder.mutation<IAnnualReviewOfDrivingRecord[], number | void>({
            query: (id) => ({
                url: `/get-annual-review-of-driving-record/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        }),
        getAnnualReviewOfDrivingRecordByUser: builder.query<IAnnualReviewOfDrivingRecord[], void>({
            query: () => ({
                url: `/get-annual-review-of-driving-record-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'AnnualReviewOfDrivingRecord', id: 'AnnualReviewOfDrivingRecord' }]
        }),
        getAnnaulReviewOfDrivingRecordByUserId: builder.query<IAnnualReviewOfDrivingRecord[], number | void>({
            query: (id) => ({
                url: `/get-annual-review-of-driving-record-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'AnnualReviewOfDrivingRecord', id: 'AnnualReviewOfDrivingRecord' }]
        }),
        getNonCurrentAnnualReviewOfDrivingRecordByUserId: builder.query<IAnnualReviewOfDrivingRecord[], number | void>({
            query: (id) => ({
                url: `/get-non-current-annual-review-of-driving-record-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'AnnualReviewOfDrivingRecord', id: 'AnnualReviewOfDrivingRecord' }]
        }),
        getAnnualReviewReminder: builder.mutation<IReminder, IReminderInput>({
            query: (body) => ({
                url: `/get-reminder/`,
                method: 'POST',
                body: {
                    company_id: body.company_id,
                    member_id: body.member_id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'AnnualReviewOfDrivingRecord', id: 'AnnualReviewOfDrivingRecord' }]
        })
    })
});

export const {
    useCreateAnnualReviewOfDrivingRecordMutation,
    useUpdateAnnualReviewOfDrivingRecordMutation,
    useGetAnnualReviewOfDrivingRecordMutation,
    useGetAnnualReviewOfDrivingRecordByUserQuery,
    useGetAnnaulReviewOfDrivingRecordByUserIdQuery,
    useGetAnnualReviewReminderMutation,
    useGetNonCurrentAnnualReviewOfDrivingRecordByUserIdQuery
} = annualReviewOfDrivingRecordApi;
