import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    MenuItem,
    Slide,
    TextField
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IMaintenanceMember } from '../../../store/interfaces/PreventableMaintenance/maintenance.members';
import { useCompany } from '../../../context/CompanyContext';
import {
    useCreateMaintenanceMemberMutation,
    useUpdateMaintenanceMemberMutation
} from '../../../store/reducers/PreventableMaintenance/maintenance.member';
import { useAuth } from '../../modules/auth';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues: Partial<IMaintenanceMember> = {
    member: undefined,
    first_name: '',
    last_name: '',
    phone_number: '',
    email: ''
};

const validationSchema = Yup.object().shape({
    member: Yup.object(),
    first_name: Yup.string().when('member', {
        is: undefined,
        then: Yup.string().required('First Name is required'),
        otherwise: Yup.string().notRequired()
    }),
    last_name: Yup.string().when('member', {
        is: undefined,
        then: Yup.string().required('Last Name is required'),
        otherwise: Yup.string().notRequired()
    }),
    email: Yup.string().when('member', {
        is: undefined,
        then: Yup.string().required('Email is required'),
        otherwise: Yup.string().notRequired()
    }),
    phone_number: Yup.string().when('member', {
        is: undefined,
        then: Yup.string().required('Phone Number is required'),
        otherwise: Yup.string().notRequired()
    })
});

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    maintenanceMember?: IMaintenanceMember;
}

export const AddMaintenanceMember = ({ open, setOpen, maintenanceMember }: Readonly<IProps>) => {
    const [isCurrentMember, setIsCurrentMember] = React.useState<boolean>(true);
    const { currentCompany } = useCompany();
    const [createMaintenanceMember, { isLoading, isSuccess, isError, error }] = useCreateMaintenanceMemberMutation();
    const [updateMaintenanceMember, { isLoading: updateLoading, isSuccess: updateSucess, error: updateError }] =
        useUpdateMaintenanceMemberMutation();
    const { currentUser } = useAuth();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (maintenanceMember) {
                updateMaintenanceMember({
                    ...maintenanceMember,
                    ...values
                }).catch((err) => {
                    toast.error(err.error.message || 'An error occurred');
                });
            } else {
                createMaintenanceMember({
                    ...values,
                    company: currentUser?.company,
                    phone_number: values.phone_number?.startsWith('+')
                        ? values.phone_number
                        : `+1${values.phone_number}`
                }).catch((err) => {
                    toast.error(err.error.message || 'An error occurred');
                });
            }
        }
    });

    useEffect(() => {
        if (isSuccess || updateSucess) {
            setOpen(false);
            toast.success('Maintenance Member added successfully');
            formik.resetForm();
        }
        if (error || updateError) {
            toast.error('An error occurred');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, isError, updateSucess, updateError]);

    useEffect(() => {
        if (open) {
            if (maintenanceMember) {
                formik.setValues(maintenanceMember);
                setIsCurrentMember(false);
            }
        } else {
            formik.resetForm();
            setIsCurrentMember(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, maintenanceMember]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding: '20px 20px 20px 20px'
                }}
            >
                {maintenanceMember ? 'Update Maintenance Member' : 'Add Maintenance Member'}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Is Current Member?"
                                checked={isCurrentMember}
                                onChange={() => setIsCurrentMember(!isCurrentMember)}
                            />
                        </Grid>
                        {isCurrentMember ? (
                            <Grid item xs={12} m={2}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    select
                                    label="Member"
                                    name="member"
                                    value={formik.values.member}
                                    onChange={(e) => {
                                        const member = currentCompany?.members.find(
                                            (member) => Number(member.id) === Number(e.target.value)
                                        );
                                        if (member) {
                                            formik.setFieldValue('member', member);
                                        }
                                    }}
                                    error={formik.touched.member && Boolean(formik.errors.member)}
                                    helperText={formik.touched.member && formik.errors.member}
                                >
                                    <MenuItem value={undefined}>Select Member</MenuItem>
                                    {currentCompany?.members.map((member) => (
                                        <MenuItem key={member.id} value={member.id}>
                                            {member?.user.first_name} {member?.user.last_name} - {member?.user.email}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12} m={2}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="First Name"
                                        name="first_name"
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                        helperText={formik.touched.first_name && formik.errors.first_name}
                                    />
                                </Grid>
                                <Grid item xs={12} m={2}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Last Name"
                                        name="last_name"
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                        helperText={formik.touched.last_name && formik.errors.last_name}
                                    />
                                </Grid>
                                <Grid item xs={12} m={2}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} m={2}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Phone Number"
                                        name="phone_number"
                                        value={formik.values.phone_number}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                        helperText={formik.touched.phone_number && formik.errors.phone_number}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Button
                                disabled={isLoading || !formik.isValid || updateLoading}
                                size="small"
                                onClick={() => formik.handleSubmit()}
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {maintenanceMember ? 'Update Maintenance Member' : 'Add Maintenance Member'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};
