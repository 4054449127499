import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    ISafetyPerformanceHistoryDto,
    IDriverAccidentHistory,
    IDriverAccidentHistoryDto,
    ISafetyPerformanceHistory
} from '../../interfaces/PreviousEmployeeSafetyPerformanceHistory/safety-performance-history';

export const safetyPerformanceHistoryApi = createApi({
    reducerPath: 'safety_performance_history',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/safety-performance-history`
    }),
    keepUnusedDataFor: 60 * 10, // 10 minutes
    tagTypes: ['SafetyPerformanceHistory'],
    endpoints: (builder) => ({
        createSafetyPerformanceHistory: builder.mutation<ISafetyPerformanceHistory, ISafetyPerformanceHistoryDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'SafetyPerformanceHistory',
                    id: 'SafetyPerformanceHistory'
                }
            ]
        }),
        updateSafetyPerformanceHistory: builder.mutation<ISafetyPerformanceHistory, ISafetyPerformanceHistory>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'SafetyPerformanceHistory',
                    id: 'SafetyPerformanceHistory'
                }
            ]
        }),
        getSafetyPerformanceHistory: builder.query<ISafetyPerformanceHistory[], number | void>({
            query: (id) => ({
                url: `/get-safety-performance-history/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'SafetyPerformanceHistory',
                    id: 'SafetyPerformanceHistory'
                }
            ]
        }),
        getSafetyPerformanceHistoryByUser: builder.query<ISafetyPerformanceHistory[], void>({
            query: () => ({
                url: `/get-safety-performance-history-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'SafetyPerformanceHistory',
                    id: 'SafetyPerformanceHistory'
                }
            ]
        }),
        getSafetyPerformanceHistoryByUserId: builder.query<ISafetyPerformanceHistory[], number>({
            query: (id) => ({
                url: `/get-safety-performance-history-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'SafetyPerformanceHistory',
                    id: 'SafetyPerformanceHistory'
                }
            ]
        }),
        deleteSafetyPerformanceHistory: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        })
    })
});

export const driverAccidentHistoryApi = createApi({
    reducerPath: 'driver_accident_history',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/driver-accident-history`
    }),
    tagTypes: ['DriverAccidentHistory'],
    endpoints: (builder) => ({
        createDriverAccidentHistory: builder.mutation<IDriverAccidentHistory, IDriverAccidentHistoryDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'DriverAccidentHistory',
                    id: 'DriverAccidentHistory'
                }
            ]
        }),
        updateDriverAccidentHistory: builder.mutation<IDriverAccidentHistory, IDriverAccidentHistory>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'DriverAccidentHistory',
                    id: 'DriverAccidentHistory'
                }
            ]
        }),
        getDriverAccidentHistory: builder.query<IDriverAccidentHistory[], number | void>({
            query: (id) => ({
                url: `/get-driver-accident-history/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'DriverAccidentHistory',
                    id: 'DriverAccidentHistory'
                }
            ]
        }),
        getDriverAccidentHistoryByUser: builder.query<IDriverAccidentHistory[], void>({
            query: () => ({
                url: `/get-driver-accident-history-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'DriverAccidentHistory',
                    id: 'DriverAccidentHistory'
                }
            ]
        }),
        getDriverAccidentHistoryByUserId: builder.query<IDriverAccidentHistory[], number | undefined>({
            query: (id) => ({
                url: `/get-driver-accident-history-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [
                {
                    type: 'DriverAccidentHistory',
                    id: 'DriverAccidentHistory'
                }
            ]
        }),
        deleteDriverAccidentHistory: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [
                {
                    type: 'DriverAccidentHistory',
                    id: 'DriverAccidentHistory'
                }
            ]
        })
    })
});

export const {
    useCreateSafetyPerformanceHistoryMutation,
    useUpdateSafetyPerformanceHistoryMutation,
    useGetSafetyPerformanceHistoryQuery,
    useGetSafetyPerformanceHistoryByUserQuery,
    useGetSafetyPerformanceHistoryByUserIdQuery,
    useDeleteSafetyPerformanceHistoryMutation
} = safetyPerformanceHistoryApi;

export const {
    useCreateDriverAccidentHistoryMutation,
    useUpdateDriverAccidentHistoryMutation,
    useGetDriverAccidentHistoryQuery,
    useGetDriverAccidentHistoryByUserQuery,
    useGetDriverAccidentHistoryByUserIdQuery,
    useDeleteDriverAccidentHistoryMutation
} = driverAccidentHistoryApi;
