import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IProgress } from '../../interfaces/abstract';

export const previousEmployeeSafetyPerformanceHistoryProgress = createApi({
    reducerPath: 'previousEmployeeSafetyPerformanceHistoryProgress',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/prev-employee-safety-performance-history-progress`
    }),
    tagTypes: ['PreviousEmployeeSafetyPerformanceHistoryProgress'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        getPreviousEmployeeSafetyPerformanceHistoryProgress: builder.mutation<IProgress, number>({
            query: (id) => ({
                url: `/total-progress/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        }),
        getMemberPreviousEmployeeSafetyPerformanceHistoryProgress: builder.mutation<IProgress, number>({
            query: (id) => ({
                url: `/member-progress/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        })
    })
});

export const {
    useGetPreviousEmployeeSafetyPerformanceHistoryProgressMutation,
    useGetMemberPreviousEmployeeSafetyPerformanceHistoryProgressMutation
} = previousEmployeeSafetyPerformanceHistoryProgress;
