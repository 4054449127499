/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { PasswordMeterComponent } from '../../../../compliancemasters/assets/ts/components';
import { useAuth } from '../core/Auth';
import useNavigation from '../../../../shared/useNavigation/useNavigation';
import { TermsModal } from './TermsModal';
// can probably remove this
const urlParams = new URLSearchParams(window.location.href);

const companyName = urlParams.get('company')?.replace('+', ' ');

/*
There could consider making initial values vary depending on if you are registering as a company or a member

make an object with just the member related initial values and then use the spread operator to add it to the
company related initial values only if the user is registering as a member.

For example:

const memberRegistrationInitialValues = { # member related initial values }

const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    changepassword: '',
    company: urlParams.values().next().value,
    acceptTerms: false,
    {...companyName !== "" ? memberRegistrationInitialValues : {}}
*/

const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    changepassword: '',
    company: companyName,
    driver_type: urlParams.get('driver-type')?.replace('+', ' '),
    acceptTerms: false,
    role: window.location.href.includes('company') ? 'Member' : 'Owner',
    license: '',
    birth: null,
    state: ``,
    social_security_number: '',
    drivers_license_number: '',
    phone_number: ''
};

/*
You should consider adding the member related validations only if the user is registering as a member

You can tell if you are registering as a company or member by seeing if the url has a company name in it
and from there you can add the member related validations only if the user is registering as a member

make an object with just the member related validations and then use the spread operator to add it to the
company related validations only if the user is registering as a member.

For example:

const memberRegistrationSchema = { # member related validations }

const validationSchema = Yup.object().shape({
    # first name, last name, email, password
    ...companyName !== "" ? memberRegistrationSchema : {}
})

*/

const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('First name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    lastname: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Last name is required'),
    password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
    changepassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match")
        }),
    acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
    state: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('State is required'),
    birth: Yup.date().max(new Date(), 'Date of birth cannot be in the future').required('Date of birth is required'),
    social_security_number: Yup.string()
        .min(6, 'Minimum 6 symbols')
        .max(9, 'Maximum 9 symbols')
        .required('Social Security Number is required'),
    phone_number: Yup.string().required('Phone Number is required'),
    drivers_license_number: Yup.string().required('Drivers License Number is required')
});

const registrationOwnerSchema = Yup.object().shape({
    firstname: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('First name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    lastname: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Last name is required'),
    password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
    changepassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match")
        }),
    acceptTerms: Yup.bool().required('You must accept the terms and conditions')
    // drivers_license_number: Yup.string().required("Drivers License Number is required")
});

export function Registration() {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const { saveAuth, setCurrentUser, getUser, register, login, currentUser } = useAuth();
    const { navigateToCompany, navigateToDashboard } = useNavigation();
    const formik = useFormik({
        initialValues,
        validationSchema: window.location.href.includes('company') ? registrationSchema : registrationOwnerSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            function errorHandler(error: any) {
                saveAuth(undefined);
                setStatus('The registration details is incorrect');
                completedRequest();
            }

            function completedRequest() {
                setSubmitting(false);
                setLoading(false);
            }

            setLoading(true);

            register({
                email: values.email,
                firstname: values.firstname,
                lastname: values.lastname,
                password: values.password,
                passwordConfirmation: values.changepassword,
                company: urlParams.values().next().value!,
                birth: values.birth!,
                state: values.state,
                driver_type: values.driver_type,
                social_security_number: values.social_security_number,
                phone_number: values.phone_number,
                drivers_license_number: values.drivers_license_number,
                role: window.location.href.includes('company') ? 'Member' : 'Owner'
            })
                .then(() => {
                    login(values.email, values.password)
                        .then((auth) => {
                            saveAuth({
                                api_token: auth.access,
                                refreshToken: auth.refresh
                            });
                            getUser()
                                .then((users) => {
                                    setCurrentUser({ ...users[0], otpConfirmed: true });
                                    completedRequest();
                                })
                                .catch((error) => errorHandler(error));
                        })
                        .catch((error) => errorHandler(error));
                })
                .catch((error) => errorHandler(error));
        }
    });

    useEffect(() => {
        PasswordMeterComponent.bootstrap();
    }, []);

    useEffect(() => {
        if (currentUser) {
            if (urlParams.values().next().value! === '') {
                navigateToCompany();
            } else {
                navigateToDashboard();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    /*
    You could probably do something like urlParams.get('company') instead of urlParams.values().next()
    move this to the top with the consts, and make a const for the company name like so

    const companyName = urlParams.get('company').replace('+', ' ')

    use companyName instead of urlParams.values().next().value.replace('+', ' ')!

    */
    const urlParams = new URLSearchParams(window.location.href);

    /*
    You could consider hiding the non member related fields if the user is registering as a company
    */

    return (
        <>
            <form
                className="form w-100 p-5 fv-plugins-bootstrap5 fv-plugins-framework"
                noValidate
                id="kt_login_signup_form"
                onSubmit={formik.handleSubmit}
            >
                {/* begin::Heading */}
                <div className="mb-10 text-center">
                    {/* begin::Title */}
                    <h1 className="text-dark mb-3">Create an Account</h1>
                    {/* end::Title */}

                    {/* begin::Link */}
                    {window.location.href.includes('company') ? null : (
                        <div className="text-gray-400 fw-bold fs-4">
                            Already have an account?
                            <Link to="/auth/login" className="link-primary fw-bolder" style={{ marginLeft: '5px' }}>
                                Sign in here
                            </Link>
                        </div>
                    )}
                    {/* end::Link */}
                </div>
                {/* end::Heading */}

                {formik.status && (
                    <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}

                {window.location.href.includes('company') && (
                    <div className="fv-row mb-7">
                        <label className="form-label fw-bolder text-dark fs-6">Company</label>
                        <input
                            placeholder="Company"
                            type="Company"
                            autoComplete="off"
                            readOnly
                            {...formik.getFieldProps('company')}
                            value={urlParams?.values()?.next()?.value?.replace('+', ' ')!}
                            className={clsx('form-control form-control-lg form-control-solid')}
                        />
                    </div>
                )}
                {/* begin::Form group Firstname */}
                <div className="row fv-row mb-7">
                    <div className="col-xl-6">
                        <label className='class="form-label fw-bolder text-dark fs-6 mb-2'>First name</label>
                        <input
                            placeholder="First name"
                            type="text"
                            autoComplete="off"
                            {...formik.getFieldProps('firstname')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.touched.firstname && formik.errors.firstname
                                },
                                {
                                    'is-valid': formik.touched.firstname && !formik.errors.firstname
                                }
                            )}
                        />
                        {formik.touched.firstname && formik.errors.firstname && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.firstname}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-xl-6">
                        {/* begin::Form group Lastname */}
                        <div className="fv-row mb-5">
                            <label className="form-label fw-bolder text-dark fs-6">Last name</label>
                            <input
                                placeholder="Last name"
                                type="text"
                                autoComplete="off"
                                {...formik.getFieldProps('lastname')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.lastname && formik.errors.lastname
                                    },
                                    {
                                        'is-valid': formik.touched.lastname && !formik.errors.lastname
                                    }
                                )}
                            />
                            {formik.touched.lastname && formik.errors.lastname && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">{formik.errors.lastname}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* end::Form group */}
                    </div>
                </div>
                {/* end::Form group */}
                {window.location.href.includes('company') && (
                    <div className="fv-row mb-5">
                        <label className="form-label fw-bolder text-dark fs-6">Date of Birth</label>
                        <input
                            placeholder=""
                            type="date"
                            autoComplete="off"
                            {...formik.getFieldProps('birth')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.touched.birth && formik.errors.birth
                                },
                                {
                                    'is-valid': formik.touched.birth && !formik.errors.birth
                                }
                            )}
                        />
                        {formik.touched.birth && formik.errors.birth && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{JSON.stringify(formik.errors.birth)}</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {/* begin::Form group Email */}
                <div className="fv-row mb-7">
                    <label className="form-label fw-bolder text-dark fs-6">Email</label>
                    <input
                        placeholder="Email"
                        type="email"
                        autoComplete="off"
                        {...formik.getFieldProps('email')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.email && formik.errors.email
                            },
                            {
                                'is-valid': formik.touched.email && !formik.errors.email
                            }
                        )}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{formik.errors.email}</span>
                            </div>
                        </div>
                    )}
                    {window.location.href.includes('company') && (
                        <span className="form-text text-muted">
                            Please enter the email that was used to send you the sign up link
                        </span>
                    )}
                </div>
                {/* end::Form group */}

                {window.location.href.includes('company') && (
                    <div className="fv-row mb-5">
                        <label className="form-label fw-bolder text-dark fs-6">Phone Number</label>
                        <input
                            placeholder="Phone Number"
                            type="text"
                            autoComplete="off"
                            {...formik.getFieldProps('phone_number')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.touched.phone_number && formik.errors.phone_number
                                },
                                {
                                    'is-valid': formik.touched.phone_number && !formik.errors.phone_number
                                }
                            )}
                        />
                        {formik.touched.phone_number && formik.errors.phone_number && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.phone_number}</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/* begin::Form group Password */}
                <div className="mb-10 fv-row" data-kt-password-meter="true">
                    <div className="mb-1">
                        <label className="form-label fw-bolder text-dark fs-6">Password</label>
                        <div className="position-relative mb-3">
                            <input
                                type="password"
                                placeholder="Password"
                                autoComplete="off"
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password
                                    },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password
                                    }
                                )}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* begin::Meter */}
                        <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                        </div>
                        {/* end::Meter */}
                    </div>
                    <div className="text-muted">Use 8 or more characters with a mix of letters, numbers & symbols.</div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group Confirm password */}
                <div className="fv-row mb-5">
                    <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
                    <input
                        type="password"
                        placeholder="Password confirmation"
                        autoComplete="off"
                        {...formik.getFieldProps('changepassword')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.changepassword && formik.errors.changepassword
                            },
                            {
                                'is-valid': formik.touched.changepassword && !formik.errors.changepassword
                            }
                        )}
                    />
                    {formik.touched.changepassword && formik.errors.changepassword && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{formik.errors.changepassword}</span>
                            </div>
                        </div>
                    )}
                </div>
                {window.location.href.includes('company') && (
                    <>
                        {/* begin::Form group Lastname */}
                        <div className="fv-row mb-5">
                            <label className="form-label fw-bolder text-dark fs-6">Drivers License Number</label>
                            <input
                                placeholder="Drivers License Number"
                                type="text"
                                autoComplete="off"
                                {...formik.getFieldProps('drivers_license_number')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid':
                                            formik.touched.drivers_license_number &&
                                            formik.errors.drivers_license_number
                                    },
                                    {
                                        'is-valid':
                                            formik.touched.drivers_license_number &&
                                            !formik.errors.drivers_license_number
                                    }
                                )}
                            />
                            {formik.touched.drivers_license_number && formik.errors.drivers_license_number && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        <span role="alert">{formik.errors.drivers_license_number}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* end::Form group */}
                    </>
                )}
                {window.location.href.includes('company') && (
                    <div className="fv-row mb-5">
                        <label className="form-label fw-bolder text-dark fs-6">Social Security Number</label>
                        <input
                            placeholder="Social Security Number"
                            type="text"
                            autoComplete="off"
                            {...formik.getFieldProps('social_security_number')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid':
                                        formik.touched.social_security_number && formik.errors.social_security_number
                                },
                                {
                                    'is-valid':
                                        formik.touched.social_security_number && !formik.errors.social_security_number
                                }
                            )}
                        />
                        {formik.touched.social_security_number && formik.errors.social_security_number && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.social_security_number}</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {/* end::Form group */}

                {window.location.href.includes('company') && (
                    <div className="fv-row mb-7">
                        <label className="form-label fw-bolder text-dark fs-6">Registered State</label>
                        <input
                            placeholder="State"
                            type="state"
                            autoComplete="off"
                            {...formik.getFieldProps('state')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.touched.state && formik.errors.state
                                },
                                {
                                    'is-valid': formik.touched.state && !formik.errors.state
                                }
                            )}
                        />
                        {formik.touched.state && formik.errors.state && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.state}</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {/* begin::Form group */}
                <div className="fv-row mb-10">
                    <div className="form-check form-check-custom form-check-solid">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="kt_login_toc_agree"
                            {...formik.getFieldProps('acceptTerms')}
                        />
                        <label className="form-check-label fw-bold text-gray-700 fs-6" htmlFor="kt_login_toc_agree">
                            I Agree the{' '}
                            <Link
                                to="/auth/terms"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpen(true);
                                }}
                                className="ms-1 link-primary"
                            >
                                terms and conditions
                            </Link>
                            .
                        </label>
                        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.acceptTerms}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className="text-center">
                    {loading ? (
                        <button className="btn btn-lg btn-primary w-100 mb-5" disabled>
                            <span className="indicator-progress" style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    ) : (
                        <button
                            type="submit"
                            id="kt_sign_up_submit"
                            className="btn btn-lg btn-primary w-100 mb-5"
                            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                        >
                            <span className="indicator-label">Submit</span>
                        </button>
                    )}

                    <Link to="/auth/login">
                        <button
                            type="button"
                            id="kt_login_signup_form_cancel_button"
                            className="btn btn-lg btn-light-primary w-100 mb-5"
                        >
                            Cancel
                        </button>
                    </Link>
                </div>
                {/* end::Form group */}
            </form>
            <TermsModal open={open} setOpen={setOpen} />
        </>
    );
}
