import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
    ICertificateOfRoadTest,
    ICertificateOfRoadTestDto
} from '../../interfaces/CertificateOfRoadTest/certificate-of-road-test';

export const certificateOfRoadTestApi = createApi({
    reducerPath: 'certificate_of_road_test',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/certificate-of-road-test`
    }),
    tagTypes: ['CertificateOfRoadTest'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createCertificateOfRoadTest: builder.mutation<ICertificateOfRoadTest, ICertificateOfRoadTestDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'CertificateOfRoadTest', id: 'CertificateOfRoadTest' }]
        }),
        updateCertificateOfRoadTest: builder.mutation<ICertificateOfRoadTest, ICertificateOfRoadTest>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: `PUT`,
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'CertificateOfRoadTest', id: 'CertificateOfRoadTest' }]
        }),
        deleteCertificateOfRoadTest: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'CertificateOfRoadTest', id: 'CertificateOfRoadTest' }]
        }),
        getCertificateOfRoadTest: builder.mutation<ICertificateOfRoadTest, number | void>({
            query: (id) => ({
                url: `/get-certificate-of-road-test/`,
                method: 'POST',
                body: {
                    memberId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        }),
        getCertificateOfRoadTestByUser: builder.query<ICertificateOfRoadTest, void>({
            query: () => ({
                url: `/get-certificate-of-road-test-by-user/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'CertificateOfRoadTest', id: 'CertificateOfRoadTest' }]
        }),
        getCertificateOfRoadTestByUserId: builder.query<ICertificateOfRoadTest, number | void>({
            query: (id) => ({
                url: `/get-certificate-of-road-test-by-user-id/`,
                method: 'POST',
                body: {
                    userId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'CertificateOfRoadTest', id: 'CertificateOfRoadTest' }]
        })
    })
});

export const {
    useCreateCertificateOfRoadTestMutation,
    useUpdateCertificateOfRoadTestMutation,
    useGetCertificateOfRoadTestMutation,
    useGetCertificateOfRoadTestByUserQuery,
    useGetCertificateOfRoadTestByUserIdQuery,
    useDeleteCertificateOfRoadTestMutation
} = certificateOfRoadTestApi;
