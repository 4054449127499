import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import { useCompany } from '../../../context/CompanyContext';
import { useAuth } from '../../modules/auth';
import { IDVIReport } from '../../../store/interfaces/PreventableMaintenance/dvir.report';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    useGetDvirReportQuery,
    useUpdateDvirReportMutation
} from '../../../store/reducers/PreventableMaintenance/dvir.report';
import { useGetMaintenanceVehicleQuery } from '../../../store/reducers/PreventableMaintenance/maintenance.vehicle';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetMaintenanceMembersByCompanyQuery } from '../../../store/reducers/PreventableMaintenance/maintenance.member';
import TruckCheckList from '../driver-inspection-report/TruckCheckList';
import TrailerChecklist from '../driver-inspection-report/TrailerChecklist';

const initialValues: Partial<IDVIReport> = {
    company: undefined,
    member: undefined,
    date: new Date().toISOString(),
    time: '',
    start_odometer_reading: '',
    end_odometer_reading: '',
    remarks: '',
    condition_satisfactory: false,
    driver_signature: '',
    defects_corrected: false,
    defects_need_correcting: false,
    mechanic_signature: '',
    next_driver_signature: '',
    vehicle: undefined,
    maintenance_member: undefined,
    mechanic_notes: ''
};

const validationSchema = Yup.object().shape({
    date: Yup.string().required('Date is required'),
    time: Yup.string().required('Time is required'),
    start_odometer_reading: Yup.string().required('Start Odometer Reading is required'),
    driver_signature: Yup.string().required('Driver Signature is required'),
    vehicle: Yup.object().required('Vehicle is required')
});

const MaintenanceMemberReport = () => {
    const { id } = useParams<{ id: string }>();
    const [dviReport, setDviReport] = React.useState<IDVIReport>();
    const [createReport, setCreateReport] = React.useState(false);
    const { currentCompany } = useCompany();
    const { currentUser } = useAuth();
    const [updateDviReport, { isLoading: updateLoading, data: updated }] = useUpdateDvirReportMutation();
    const { data: report } = useGetDvirReportQuery(Number(id), {
        refetchOnFocus: true,
        skip: !id
    });

    const { data: maintenanceMembers = [] } = useGetMaintenanceMembersByCompanyQuery(
        Number(currentUser?.maintenance_member?.company),
        {
            refetchOnFocus: true,
            skip: !currentUser?.maintenance_member?.company
        }
    );

    const { data: vehicle } = useGetMaintenanceVehicleQuery(Number(report?.vehicle?.id), {
        refetchOnFocus: true,
        skip: !report?.vehicle?.id
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            if (report) {
                await updateDviReport({
                    ...values,
                    id: report.id
                }).then(() => {
                    setCreateReport(true);
                    toast.success(`Thank you for completing this DVIR. It has been stored in the vehicle’s profile.`);
                    setTimeout(() => {
                        if (currentUser?.role === 'Owner') {
                            return window.location.assign('/preventable-maintenance');
                        }
                        window.location.assign('/preventable-maintenance/dashboard');
                    }, 3000);
                });
            }
        }
    });

    useEffect(() => {
        if (report) {
            setDviReport(report);
            formik.setValues(report);
        }
        if (updated) {
            setDviReport(updated);
            formik.setValues(updated);
        }
        // eslint-disable-next-line
    }, [report, updated]);

    useEffect(() => {
        if (vehicle) {
            formik.setFieldValue('vehicle', vehicle);
        }
        // eslint-disable-next-line
    }, [vehicle]);

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    window.history.back();
                }}
                sx={{ borderRadius: 2, width: 200, height: 40, mt: 4 }}
            >
                {'Back to Dashboard'}
            </Button>
            <Box
                sx={{
                    flexGrow: 1,
                    width: {
                        xs: '100%',
                        sm: 'calc(100% - 256px)',
                        md: 'calc(100% - 256px)',
                        lg: 'calc(100% - 256px)'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: 2
                }}
                component={Paper}
                elevation={3}
                boxShadow={3}
                mt={5}
            >
                <Grid
                    container
                    spacing={3}
                    sx={{
                        width: {
                            xs: '100%',
                            sm: '75%',
                            md: '65%',
                            lg: '60%'
                        },
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            Driver-Vehicle Inspection Report (DVIR)
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '65%',
                                lg: '60%'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <InputLabel>Company Name:</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={
                                currentCompany
                                    ? currentCompany.name
                                    : currentUser?.company?.name ?? formik.values.company?.name ?? ''
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '65%',
                                lg: '60%'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <InputLabel>Company Address:</InputLabel>
                        {/* <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            disabled
                            value={
                                currentCompany
                                    ? `${currentCompany?.address1}, ${currentCompany.city}, ${currentCompany?.state}, ${currentCompany?.zip_code}`
                                    : `${currentUser?.company?.city}, ${currentUser?.company?.state}, ${currentUser?.company?.zip_code}` ??
                                      formik.values.company?.address1 ??
                                      ''
                            }
                        /> */}
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            disabled
                            value={(() => {
                                if (currentCompany) {
                                    return [
                                        currentCompany.address1,
                                        currentCompany.city,
                                        currentCompany.state,
                                        currentCompany.zip_code
                                    ]
                                        .filter(Boolean)
                                        .join(', ');
                                }

                                if (currentUser?.company) {
                                    return [
                                        currentUser.company.city,
                                        currentUser.company.state,
                                        currentUser.company.zip_code
                                    ]
                                        .filter(Boolean)
                                        .join(', ');
                                }

                                return formik.values.company?.address1 || '';
                            })()}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '65%',
                                lg: '60%'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <InputLabel>Driver Name:</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            disabled
                            value={`${report?.member?.user?.first_name} ${report?.member?.user?.last_name}`}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <InputLabel>Tractor / Vehicle Number:</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            disabled
                            value={formik.values.vehicle?.vehicle_number ?? ''}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <InputLabel>Trailer Vehicle Number:</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            value={formik.values.trailer?.vehicle_number}
                            variant="outlined"
                            disabled
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <InputLabel>Date:</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            disabled
                            type="date"
                            value={new Date().toISOString().split('T')[0]}
                            onChange={(e) => {
                                formik.setFieldValue('date', e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <InputLabel>Time:</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            disabled
                            type="time"
                            value={formik.values.time}
                            onChange={(e) => {
                                formik.setFieldValue('time', e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <InputLabel>Start Odometer Reading:</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            disabled
                            variant="outlined"
                            placeholder="Enter Start Odometer Reading"
                            value={formik.values.start_odometer_reading}
                            onChange={(e) => {
                                formik.setFieldValue('start_odometer_reading', e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <InputLabel>End Odometer Reading:</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            disabled
                            placeholder="Enter End Odometer Reading"
                            value={formik.values.end_odometer_reading}
                            onChange={(e) => {
                                formik.setFieldValue('end_odometer_reading', e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <Typography variant="h6" color="red">
                            Check any defective item and provide details under remarks
                        </Typography>
                    </Grid>
                    <TruckCheckList disabled dviReport={dviReport} createReport={createReport} />
                    <TrailerChecklist disabled dviReport={dviReport} createReport={createReport} />
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <InputLabel>Remarks:</InputLabel>
                        <TextField
                            fullWidth
                            multiline
                            disabled
                            rows={4}
                            size="small"
                            variant="outlined"
                            placeholder="Remarks"
                            value={formik.values.remarks}
                            onChange={(e) => {
                                formik.setFieldValue('remarks', e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox checked={formik.values.condition_satisfactory} />}
                            label="Condition of the above vehicle is satisfactory"
                            onClick={() => {
                                formik.setFieldValue('condition_satisfactory', !formik.values.condition_satisfactory);
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <InputLabel>Driver Signature:</InputLabel>
                        <TextField
                            fullWidth
                            disabled
                            variant="outlined"
                            size="small"
                            placeholder="Driver Signature"
                            value={formik.values.driver_signature}
                            onChange={(e) => {
                                formik.setFieldValue('driver_signature', e.target.value);
                            }}
                        />
                    </Grid>
                    {formik.values.maintenance_member && (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '75%',
                                    md: '60%',
                                    lg: '60%'
                                }
                            }}
                        >
                            <InputLabel>Select Maintenance Member:</InputLabel>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                disabled
                                value={formik.values.maintenance_member?.id}
                                onChange={(e) => {
                                    const maintenanceMember = maintenanceMembers.find(
                                        (member) => member.id === Number(e.target.value)
                                    );
                                    if (maintenanceMember) {
                                        formik.setFieldValue('maintenance_member', maintenanceMember);
                                    }
                                }}
                                select
                            >
                                <MenuItem value={0}>Select Maintenance Member</MenuItem>
                                {maintenanceMembers.map((member) => (
                                    <MenuItem key={member.id} value={member.id}>
                                        {member.user.first_name} {member.user.last_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Box component={Paper} boxShadow={3} elevation={2} padding={1}>
                            <Typography variant="body1" color="red" align="center">
                                Only to be completed by a Mechanic
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '75%',
                                            md: '70%',
                                            lg: '65%'
                                        }
                                    }}
                                >
                                    <FormControlLabel
                                        control={<Checkbox checked={formik.values.defects_corrected} />}
                                        label="Above defects corrected"
                                        onClick={() => {
                                            formik.setFieldValue('defects_corrected', !formik.values.defects_corrected);
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '75%',
                                            md: '70%',
                                            lg: '65%'
                                        }
                                    }}
                                >
                                    <FormControlLabel
                                        control={<Checkbox checked={formik.values.defects_need_correcting} />}
                                        label="Above defects do not need to be corrected for safe operation of the vehicle"
                                        onClick={() => {
                                            formik.setFieldValue(
                                                'defects_need_correcting',
                                                !formik.values.defects_need_correcting
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '75%',
                                            md: '70%',
                                            lg: '65%'
                                        }
                                    }}
                                >
                                    <InputLabel>Mechanic Notes:</InputLabel>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        rows={3}
                                        placeholder="Notes"
                                        value={formik.values.mechanic_notes}
                                        onChange={(e) => {
                                            formik.setFieldValue('mechanic_notes', e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '75%',
                                            md: '70%',
                                            lg: '65%'
                                        }
                                    }}
                                >
                                    <InputLabel>Mechanic Signature:</InputLabel>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        placeholder="Mechanic Signature"
                                        value={formik.values.mechanic_signature}
                                        onChange={(e) => {
                                            formik.setFieldValue('mechanic_signature', e.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <InputLabel>Next Driver Signature:</InputLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            disabled
                            size="small"
                            placeholder="Next Driver Signature"
                            value={formik.values.next_driver_signature}
                            onChange={(e) => {
                                formik.setFieldValue('next_driver_signature', e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <Typography>
                            The next driver is only required to sign this DVIR if there was an item marked defected and
                            corrected
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '75%',
                                md: '60%',
                                lg: '60%'
                            }
                        }}
                    >
                        <Button
                            variant="contained"
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={formik.submitForm}
                            disabled={!formik.isValid || updateLoading}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MaintenanceMemberReport;
